import { insertAsFirstChildIfExists } from '../../elchspucke-tuicom.service';
import { TuiOverlay } from '../../overlay.service';
import { createHTMLNode, loadScripts } from '../../util';

const sessionStorageKey = 'tui-maps-consent';

const consentApproved = () => {
    if (window.sessionStorage && window.sessionStorage.getItem(sessionStorageKey) === '1') {
        return true;
    } else {
        return false;
    }
};

export default function () {
    window.tuiCottonBall.subscribe('maps-consent', '*', 'get consent', (c, s, e, callback) => {
        if (typeof callback === 'function') {
            callback(window.sessionStorage && window.sessionStorage.getItem(sessionStorageKey) === '1');
        }
    });

    window.tuiCottonBall.subscribe('maps-consent', '*', 'set consent', (c, s, e, consentGiven) => {
        if (window.sessionStorage) {
            if (consentGiven) {
                window.sessionStorage.setItem(sessionStorageKey, '1');
            } else {
                window.sessionStorage.removeItem(sessionStorageKey);
            }
        }
    });
}

let overlay;
export function injectMapConsent({ config, onConsentApprove = () => {}, overlayId, nodeToRenderIn }) {
    loadScripts([`${config.getAWSCdnUrl()}map-consent/current/map-consent.js`]).then(() => {
        document.addEventListener(
            'map-consent.consent-approved',
            () => {
                typeof onConsentApprove === 'function' && onConsentApprove();
                overlay && overlay.hide();
            },
            { once: true }
        );

        const mapConsentElement = createHTMLNode('tui-map-consent', '', '', [
            { key: 'data-theme', value: 'tuiLight' },
            { key: 'locale', value: config.getLanguage().replace(/_/g, '-') }
        ]);
        if (nodeToRenderIn) {
            insertAsFirstChildIfExists(mapConsentElement, nodeToRenderIn);
        } else if (overlayId) {
            if (consentApproved()) {
                onConsentApprove();
            } else {
                overlay && overlay.destroy();
                overlay = new TuiOverlay({
                    overlayId,
                    title: '',
                    contentNode: mapConsentElement
                });
                overlay.show();
            }
        }
    });
}
