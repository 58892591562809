import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service.js';
import * as HotelBestOfferService from '../../hotel-best-offer/1.1.0/service.js';
import { formatDate } from '../../util';
import * as BannerService from '../../banner/1.0.3/service';

const translation = {
    header: {
        en: 'Book this hotel including flight',
        de: 'Dieses Hotel inklusive Flug buchen',
        fr: 'Réservez cet hôtel, vol compris',

        small: {
            hotel3: {
                en: 'Offers including flight & transfer',
                de: 'Angebote inklusive Flug & Transfer',
                fr: 'Offres incluant vol & transfert'
            },
            hotel4: {
                en: 'This hotel including flight & transfer',
                de: 'Dieses Hotel inklusive Flug & Transfer',
                fr: 'Cet hôtel incluant vol & transfert'
            }
        }
    },
    ctaButton: {
        per_person: {
            en: 'Offers from ${amount} p.p.',
            de: 'Angebote ab ${amount} p.P.',
            fr: 'Offres de p.p ${amount}.',

            small: {
                en: 'from ${amount} p.p.',
                de: 'ab ${amount} p.P.',
                // "fr": "de ${amount} p.p.",
                fr: 'dès p.p. ${amount}'
            }
        },
        total: {
            en: 'Offers from ${amount}',
            de: 'Angebote ab ${amount}',
            fr: 'Offres de ${amount}',

            small: {
                en: 'from ${amount}',
                de: 'ab ${amount}',
                fr: 'de ${amount}'
            }
        },
        search: {
            en: 'search now',
            de: 'Jetzt suchen',
            fr: 'Echercher maintenant'
        }
    }
};

const checkIbeSearchResultData = (ibeSearchResultData) => {
    if (
        ibeSearchResultData &&
        ibeSearchResultData.stepNumber &&
        ibeSearchResultData.searchScope &&
        ibeSearchResultData.data &&
        ibeSearchResultData.data.hotel &&
        ibeSearchResultData.data.hotel.giataId
    ) {
        return true;
    }
    return false;
};

const checkIbeSearchResultDataH3 = (ibeSearchResultData) => {
    if (
        ibeSearchResultData &&
        ibeSearchResultData.stepNumber &&
        ibeSearchResultData.searchScope &&
        ibeSearchResultData.data &&
        ibeSearchResultData.data.region &&
        ibeSearchResultData.data.region.regionId
    ) {
        return true;
    }
    return false;
};

const getAirports = (tenant) => {
    let airports = [];
    if (tenant === 'TUICOM') {
        airports = [
            'DTM',
            'DUS',
            'FRA',
            'HHN',
            'KSF',
            'CGN',
            'FMO',
            'NRN',
            'PAD',
            'SCN',
            'BRE',
            'HAM',
            'HAJ',
            'RLG',
            'LBC',
            'BER',
            'DRS',
            'ERF',
            'LEJ',
            'FDH',
            'FKB',
            'FMM',
            'MUC',
            'NUE',
            'STR'
        ];
    } else if (tenant === 'TUIAT') {
        airports = ['GRZ', 'INN', 'KLU', 'LNZ', 'SZG', 'VIE', 'MUC'];
    } else if (tenant === 'TUICH') {
        airports = ['BRN', 'BSL', 'GVA', 'ZRH'];
    }
    return airports;
};

const injectUpsellTeaser = (tenant, ibeSearchResultData, ibeState) => {
    const hotelOfferTableHeaderElement = document.querySelector('#tuiViewSort');
    const noOfferElement = document.querySelector('.no-r');

    if (hotelOfferTableHeaderElement || noOfferElement) {
        const giataId = ibeSearchResultData.data.hotel.giataId;
        const targetContainer = noOfferElement || hotelOfferTableHeaderElement;

        _upsellTeaserContent(
            ibeState,
            tenant,
            targetContainer,
            'upsellTeaser',
            'upsellTeaser',
            giataId,
            'upsell-teaser'
        );
    }
};

const _upsellTeaserContent = (ibeState, tenant, targetContainer, teaserId, eventScope, giataId, variant) => {
    const filter = ibeState.filters;
    const searchParameters = {
        searchScope: 'PACKAGE',
        startDate: formatDate(filter.startDate),
        endDate: formatDate(filter.endDate),
        airports: getAirports(tenant),
        duration: elchspuckeTuicomService.goAndHandlePTH4Durations(filter.duration, filter.startDate, filter.endDate),
        giatas: [giataId],
        resultsTotal: 1
    };

    HotelBestOfferService.injectOrUpdateHotelBestOffer(
        targetContainer,
        teaserId,
        eventScope,
        tenant,
        giataId,
        variant,
        searchParameters,
        (cards) => {
            cards.setAttribute(
                'static-url-parameters',
                JSON.stringify({ contentid: `h4_h2p_hbo_${giataId}`, searchScope: `PACKAGE` })
            );
        }
    );

    const upsellTeaserElem = document.querySelector('tui-hotel-best-offer[data-id="upsellTeaser"]');
    const currentUrl = new URL(document.location.href);
    upsellTeaserElem.style.cursor = 'pointer';

    upsellTeaserElem.addEventListener('click', () => {
        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
            let id,
                name = '';

            if (ibeState && ibeState.country) {
                id = ibeState.filters.destination.giataId;
                name = ibeState.country.name;
            }

            currentUrl.searchParams.append('contentid', `h4_h2p_hbo_${id}_${name}`);
            currentUrl.searchParams.set('searchScope', 'PACKAGE');
            window.location.href = currentUrl;
            window.open(currentUrl, '_blank');
        });
    });
};

const removeUpsellTeaser = () => {
    const upsellTeaserElem =
        document.getElementById('upsellTeaser') ||
        document.querySelector(`tui-hotel-best-offer[data-id="upsellTeaser"]`);
    if (upsellTeaserElem) {
        elchspuckeTuicomService.removeNode(upsellTeaserElem);
    }
};

const removeUpsellTeaserSmall = () => {
    const upsellTeaserSmallElem =
        document.getElementById('upsellTeaserSmall') ||
        document.querySelector(`tui-hotel-best-offer[data-id="upsellTeaserSmall"]`);
    if (upsellTeaserSmallElem) {
        elchspuckeTuicomService.removeNode(upsellTeaserSmallElem);
    }
};

const removeElementById = (id) => {
    const element = document.getElementById(id);
    if (element) {
        elchspuckeTuicomService.removeNode(element);
    }
};

const formatMoney = (locale = 'de-DE', currency = 'EUR', amount, digits = 2) => {
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: digits,
        maximumFractionDigits: digits
    }).format(amount);
};

const getLocale = () => {
    const locale = {
        array: elchspuckeTuicomService.getDomainLocale().split('-'), // de-DE
        lang: 'de',
        country: 'DE',
        localeCode: 'de-DE',
        currenyCode: 'de-DE',
        currency: 'EUR'
    };

    locale.lang = locale.array[0].length === 2 ? locale.array[0] : locale.lang;
    locale.country = locale.array[1].length === 2 ? locale.array[1] : locale.country;
    locale.localeCode = `${locale.lang}-${locale.country}`;

    locale.currenyCode = locale.localeCode;
    if (locale.country === 'CH') {
        locale.currenyCode = `de-${locale.country}`;
        locale.currency = `CHF`;
    }

    return locale;
};

const priceButtonText = (price, showTotalPrice) => {
    const locale = getLocale();

    const token = showTotalPrice ? 'total' : 'per_person';
    const buttonText = translation.ctaButton[token].small[locale.lang];
    const buttonUpdate = buttonText.replaceAll('${amount}', formatMoney(locale.currenyCode, locale.currency, price, 0));

    return buttonUpdate;
};

const updateUpsellButtonPrice = (price, showTotalPrice) => {
    const upsellTeaserSmallElement = document.querySelector('#tui-placeholder-offerlist-position-3 tui-banner');

    if (!upsellTeaserSmallElement) return;

    upsellTeaserSmallElement.setAttribute('button-text', priceButtonText(price, showTotalPrice));
};

const injectBannerIscIntoPTH3 = (tenant) => {
    let listPosition = '5';
    const element = document.querySelector(`#tui-placeholder-hotellist-position-${listPosition}`);
    const bannerTag = BannerService.getBannerIsc('tui-banner-isc', tenant);

    element.appendChild(bannerTag);
};

const injectUpsellBannerIntoPTH3 = (ibeSearchResultData) => {
    if (checkIbeSearchResultDataH3(ibeSearchResultData)) {
        const locale = getLocale();
        const bannerText = translation.header.small.hotel3[locale.lang];
        const buttonText = translation.ctaButton.search[locale.lang];
        const currentUrl = new URL(document.location.href);

        setTimeout(function () {
            const upsellTeaserSmallElement = document.querySelector('#tui-placeholder-hotellist-position-3');

            if (upsellTeaserSmallElement) {
                const bannerData = {
                    theme: 'tui-light',
                    variant: 'upsell',
                    text: bannerText,
                    buttonText: buttonText,
                    locale: locale.localeCode
                };

                const bannerTag = BannerService.getBannerTag(bannerData, 'tui-banner-upsell');
                bannerTag.addEventListener('ctaClick', () => {
                    window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
                        let id,
                            name = '';

                        if (ibeState && ibeState.country) {
                            id = ibeState.country.id;
                            name = ibeState.country.name;
                        }

                        currentUrl.searchParams.append('contentid', `h3_h2p_slim_${id}_${name}`);
                        currentUrl.searchParams.set('searchScope', 'PACKAGE');
                        window.location.href = currentUrl;
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    });
                });

                upsellTeaserSmallElement.appendChild(bannerTag);

                window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
            }
        }, 250);
    }
};

const injectUpsellTeaserIntoPTH4 = (tenant, ibeSearchResultData, ibeState) => {
    if (checkIbeSearchResultData(ibeSearchResultData)) {
        injectUpsellTeaser(tenant, ibeSearchResultData, ibeState);
    }
};

const injectUpsellBannerIntoPTH4 = (ibeSearchResultData) => {
    if (checkIbeSearchResultData(ibeSearchResultData)) {
        const locale = getLocale();
        const bannerText = translation.header.small.hotel4[locale.lang];
        const buttonText = translation.ctaButton.search[locale.lang];
        const currentUrl = new URL(document.location.href);

        setTimeout(function () {
            const upsellTeaserSmallElement = document.querySelector('#tui-placeholder-offerlist-position-3');
            if (upsellTeaserSmallElement) {
                const bannerData = {
                    theme: 'tui-light',
                    variant: 'upsell',
                    text: bannerText,
                    buttonText: buttonText,
                    locale: locale.localeCode
                };
                const bannerTag = BannerService.getBannerTag(bannerData, 'tui-banner-upsell');

                bannerTag.addEventListener('ctaClick', () => {
                    window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
                        let giata,
                            productCode = '';

                        if (ibeState && ibeState.hotel) {
                            giata = ibeState.hotel.giata;
                            productCode = ibeState.hotel.bookingSequence;
                        }

                        currentUrl.searchParams.append('contentid', `h4_h2p_slim_${giata}_${productCode}`);
                        currentUrl.searchParams.set('searchScope', 'PACKAGE');
                        window.location.href = currentUrl;
                    });
                });
                upsellTeaserSmallElement.appendChild(bannerTag);

                window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
            }
        }, 250);
    }
};

export {
    removeUpsellTeaser,
    removeUpsellTeaserSmall,
    removeElementById,
    injectUpsellTeaserIntoPTH4,
    injectUpsellBannerIntoPTH3,
    injectUpsellBannerIntoPTH4,
    injectBannerIscIntoPTH3,
    updateUpsellButtonPrice
};
