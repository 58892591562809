
export default () => {

    // CottonBall-event to react to loaded scripts
    // window.tuiCottonBall.subscribe('mojo', 'qualtrics', 'scripts have loaded.', () => {
    let maxInterval = 100;
    const waitForQualtricsOverlay = () => {
        if (maxInterval < 1) return;

        let overlay = document.querySelector('.QSIFeedbackButton');

        if (!overlay) {
            setTimeout(waitForQualtricsOverlay, 100);
            maxInterval = maxInterval - 1;
            return;
        }

        overlay.style.zIndex = 1020;
    }

    waitForQualtricsOverlay();
    // });

    return 'qualtrics';
};
