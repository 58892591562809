import * as ibeMappingService from '../../ibe-mapping.service';
import * as apiService from '../../api-service/1.0.1/api-service';

const getDestinationId = function (ibeState) {

    if (!ibeState) {
        return false;
    }

    if (ibeState.region && ibeState.region.id && typeof ibeState.region.id === 'number') {
        return ibeState.region.id;
    } else if (ibeState.country && ibeState.country.id && typeof ibeState.country.id === 'number') {
        return ibeState.country.id;
    } else if (ibeState.filters && ibeState.filters.destination && ibeState.filters.destination.regionId && typeof ibeState.filters.destination.regionId === 'number') {
        return ibeState.filters.destination.regionId;
    }

    return false;
};

const invalidTravelPeriodCheckParameters = (travelPeriod, campaignTravelPeriod) => {

    if (!travelPeriod ||
        !travelPeriod.startDate || typeof travelPeriod.startDate.getTime !== 'function' ||
        !travelPeriod.endDate || typeof travelPeriod.endDate.getTime !== 'function') {
        return true;
    }

    if (!campaignTravelPeriod ||
        typeof campaignTravelPeriod.startDate != 'string' ||
        typeof campaignTravelPeriod.endDate != 'string') {
        return true;
    }
};

const isTravelPeriodOutsideCampaignTravelPeriod = function(travelPeriod, campaignTravelPeriod) {

    if (invalidTravelPeriodCheckParameters(travelPeriod, campaignTravelPeriod))
        return false;

    let campaignTravelStartDate = new Date(campaignTravelPeriod.startDate);
    let campaignTravelEndDate = new Date(campaignTravelPeriod.endDate);

    return (travelPeriod.endDate.getTime() < campaignTravelStartDate.getTime() ||
            travelPeriod.startDate.getTime() > campaignTravelEndDate.getTime());
};

const isTravelPeriodWithinCampaignTravelPeriod = function(travelPeriod, campaignTravelPeriod) {

    if (invalidTravelPeriodCheckParameters(travelPeriod, campaignTravelPeriod))
        return false;

    let campaignTravelStartDate = new Date(campaignTravelPeriod.startDate);
    let campaignTravelEndDate = new Date(campaignTravelPeriod.endDate);

    return (travelPeriod.startDate.getTime() >= campaignTravelStartDate.getTime() &&
            travelPeriod.endDate.getTime() <= campaignTravelEndDate.getTime());
};

const isValidCampaignDuration = function(searchPeriod, campaignOffersSpecification) {

    if(!searchPeriod || !searchPeriod.startDate || !searchPeriod.endDate ||
        !campaignOffersSpecification || typeof campaignOffersSpecification.minimumDuration !== 'number') {
        return false;
    }
    let searchTravelPeriodData = ibeMappingService.mapIBETravelPeriod(searchPeriod.duration, searchPeriod.startDate, searchPeriod.endDate);

    if (searchTravelPeriodData && searchTravelPeriodData.durations) {
        let searchDurations = searchTravelPeriodData.durations;

        let searchMaxDuration = searchDurations.length !== 0 ? searchDurations[searchDurations.length - 1] : 1;

        if (searchMaxDuration >= campaignOffersSpecification.minimumDuration) {
            return true;
        }
    }

    return false;
};

//TODO hand over object that defines what valid means -> how many adults, how many children, what age
const getValidCampaignTravellers = function (originalParsedUrl, campaignOffersSpecification) {

    if (!originalParsedUrl ||
        typeof originalParsedUrl !== 'object' ||
        originalParsedUrl.length <= 0 ||
        !campaignOffersSpecification ||
        !campaignOffersSpecification.defaultTravellers)
    {
        return false;
    }

    let searchTravellers = ibeMappingService.mapIBETravellers(originalParsedUrl);

    if (!searchTravellers || !isThereAChild(searchTravellers)) {
        return [{
            adults: campaignOffersSpecification.defaultTravellers.adults,
            children: campaignOffersSpecification.defaultTravellers.children
        }];
    }

    return searchTravellers;
};

const isThereAChild = function (searchTravellers) {
    for (let traveller in searchTravellers) {
        if (searchTravellers[traveller].children && searchTravellers[traveller].children.length > 0) {
            return true;
        }
    }

    return false;
};

const generateLandingPageLink = function (plattformHost, landingpageURL, campaignName, contentId) {
    return plattformHost + landingpageURL + '?campaign=' + campaignName + '&contentid=' + contentId;
};

const getDestination = function (destinationId, destinations, encodeName) {

    if (destinationId && destinations && destinations[destinationId] && typeof encodeName === 'function') {
        return {id: destinationId, name: encodeName(destinations[destinationId].name)};
    }

    return false;
};

const isValueContainedInList = function(list, value) {
    if (list.indexOf(value) !== -1) {
        return true;
    } else {
        return false;
    }
};

const isAttendingDestinationForSearchScope = function (destinations, destinationId, searchScope) {

    if (!destinations || !(destinations instanceof Array) || (destinations.length < 1) || typeof destinationId !== 'string') {
        return false;
    }

    let isAttendingDestinationForSearchScope = false;
    let destinationIdInt = parseInt(destinationId);

    destinations.forEach(function (destination) {
        if (destination.id && destination.id === destinationIdInt && isValueContainedInList(destination.searchScopes, searchScope.toUpperCase())) {
            isAttendingDestinationForSearchScope = true;
        }
    });

    return isAttendingDestinationForSearchScope;
};

/**
 *
 * @param path
 * @param seoPages
 * @returns {boolean}
 */
const detectSeoPage = (path, seoPages) => {
    if (typeof path === 'string' && seoPages) {

        // remove first and last / of the path
        var regExp = /^\/|\/$/g;
        path = path.replace(regExp, '');

        for (var i = 0; i < seoPages.length; i++) {
            var replacedSeoPages = seoPages[i].replace(regExp, '');
            if (replacedSeoPages === path) {
                return true;
            }
        }
    }

    return false;
};

const fetchActiveCampaignInformation = (campaignServicePath, letterCode, debug) => {
    letterCode = letterCode.toUpperCase();
    let url = `${campaignServicePath}/countries/${letterCode}`;

    let body = {'active': true};

    if (debug) {
        body.debug = debug;
    }
    return apiService.getDataFromURLViaPost(url, body);
};

const getAllHotelGiataIds = (hotels) => {
    if (!hotels || !Array.isArray(hotels) || hotels.length === 0) {
        return false;
    }

    let giataIds = [];

    for (let i = 0; i < hotels.length; i++) {
        giataIds.push(hotels[i].giataId);
    }

    return giataIds;
};

const getAccoOnlyGiataIds = (hotels) => {
    if (!hotels || !Array.isArray(hotels) || hotels.length === 0) {
        return false;
    }

    let accoOnlyGiataIds = [];

    for (let i = 0; i < hotels.length; i++) {
        if (hotels[i].searchScopes && typeof hotels[i].searchScopes.indexOf === 'function' && hotels[i].searchScopes.indexOf('HOTEL') !== -1) {
            accoOnlyGiataIds.push(hotels[i].giataId);
        }
    }

    return accoOnlyGiataIds;
};

const getPackageGiataIds = (hotels) => {
    if (!hotels || !Array.isArray(hotels) || hotels.length === 0) {
        return false;
    }

    let packageGiataIds = [];

    for (let i = 0; i < hotels.length; i++) {
        if (hotels[i].searchScopes && typeof hotels[i].searchScopes.indexOf === 'function' && hotels[i].searchScopes.indexOf('PACKAGE') !== -1) {
            packageGiataIds.push(hotels[i].giataId);
        }
    }

    return packageGiataIds;
};

const getCampaignLandingPage = (completeCampaignConfig, language) => {
    if (!completeCampaignConfig ||
        !completeCampaignConfig.campaignConfiguration ||
        !Array.isArray(completeCampaignConfig.campaignConfiguration.languages) ||
        typeof language !== 'string') {
        return false;
    }

    let languageConfigs = completeCampaignConfig.campaignConfiguration.languages;

    for (let i = 0; i < languageConfigs.length; i++) {
        if (typeof languageConfigs[i].shortName === 'string' &&
            languageConfigs[i].shortName.toLowerCase() === language.toLowerCase()) {

            if (languageConfigs[i].translations && languageConfigs[i].translations.landingPage) {
                return languageConfigs[i].translations.landingPage;
            } else {
                return false;
            }
        }
    }

    return false;
}

/**
 * @param  {[type]} ibeState   [description]
 * @param  {int} stepNumber the number of the page in the funnel to generate a url for (2,3 or 4)
 * @return {Object}            the destination object to be used for IBE-Url generation
 */
const createDestinationObjectFromIBEState = (ibeState, stepNumber) => {

    let extractHotel = () => {
        if (ibeState.hotel && ibeState.hotel.giata && ibeState.hotel.name) {
            return {
                giataId: ibeState.hotel.giata,
                name: ibeState.hotel.name
            };
        }
        return false;
    };

    let extractRegion = () => {
        if (ibeState.region && ibeState.region.id && ibeState.region.name) {

            return {
                name: ibeState.region.name,
                regionId: ibeState.region.id
            }
        }
        return false;
    };

    let extractCountry = () => {
        if (ibeState.country && ibeState.country.id && ibeState.country.name) {
            return {
                name: ibeState.country.name,
                countryId: ibeState.country.id,
            }
        }
        return false;
    };

    if (stepNumber === 2) {
        return extractCountry();
    } else if (stepNumber === 3) {
        return extractRegion();
    } else if (stepNumber === 4) {
        return extractHotel();
    } else {
        // no concrete stepNumber given, find most precise match
        let destination = extractHotel();

        if (!destination) {
            destination = extractRegion();
        }

        if (!destination) {
            destination = extractCountry();
        }

        return destination;
    }
};

// this doesn't need excessive testing as it's only a debug method
const extractCCSDebugInfo = (campaignsString, fakedServiceTime) => {
    let debugInfo = {};

    if (campaignsString && typeof campaignsString.split === 'function') {
        debugInfo.campaignsForcedToBeActive = campaignsString.split(',');
    }

    if (fakedServiceTime && typeof fakedServiceTime === 'string') {
        debugInfo.fakedServiceTime = fakedServiceTime;
    }

    if (debugInfo.campaignsForcedToBeActive || debugInfo.fakedServiceTime) {
        return debugInfo;
    }

    return false;
};

export {
    getDestinationId,
    isTravelPeriodWithinCampaignTravelPeriod,
    isTravelPeriodOutsideCampaignTravelPeriod,
    isValidCampaignDuration,
    getValidCampaignTravellers,
    generateLandingPageLink,
    getDestination,
    isValueContainedInList,
    isAttendingDestinationForSearchScope,
    detectSeoPage,
    fetchActiveCampaignInformation,
    getAllHotelGiataIds,
    getAccoOnlyGiataIds,
    getPackageGiataIds,
    getCampaignLandingPage,
    createDestinationObjectFromIBEState,
    extractCCSDebugInfo
};
