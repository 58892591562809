import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service.js';
import * as util from '../../util.js';

var buildUrlsCallback = function(plattformUrl, component, scope, event, data) {

    if (!data || !data.hotels || !data.searchParameter) {
        return false;
    }

    let toBeSetFilters = mapHotelCardsParametersToIBEFilter(data.searchParameter);

    var builtURLs = [];

    for (var i = 0; i < data.hotels.length; i++) {

        let destinationObj = {
            giataId: data.hotels[i].giataId,
            name: data.hotels[i].displayName
        };

        window.tuiCottonBall.broadcast('IBE', 'generate URL',
            {
                filters: toBeSetFilters,
                destination: destinationObj,
                callback: function (component, scope, event, url) {
                    let targetUrl = util.cleanStringFromTrailingChar(plattformUrl, '/') + url;
                    targetUrl += addAffiliateParameter();
                    builtURLs.push({giata: destinationObj.giataId, url: targetUrl });
                    window.tuiCottonBall.broadcast('hotel-cards', 'Get link targets.', {iid: data.instanceId, values: builtURLs});
                }
            }
        );
    }
};

var getTUICOM_PTH_URL = function (plattformUrl, component, scope, event, data) {

    if (!data || !data.hotel || !data.searchParameter) {
        return false;
    }

    let toBeSetFilters = mapHotelCardsParametersToIBEFilter(data.searchParameter);

    let destinationObj = {
        giataId: data.hotel.giataId,
        name: data.hotel.displayName
    };

    const triggerClick = function(url) {

        if (data.props && data.props.openInNewTab === 'true') {
            window.open(url, '_blank');
        } else {
            window.location.href = url;
        }
    };

    window.tuiCottonBall.broadcast('IBE', 'generate URL',
        {
            filters: toBeSetFilters,
            destination: destinationObj,
            callback: function (component, scope, event, url) {
                let targetUrl = util.cleanStringFromTrailingChar(plattformUrl, '/') + url;
                targetUrl += addAffiliateParameter();

                triggerClick(targetUrl);
            }
        }
    );
};

// we need to keep some values for tracking, for example:
// coopid=AFF_TUI_TRIPADVISOR&utm_source=tripadvisor&utm_medium=metasearch&utm_campaign=hotelonly&utm_content=28019&utm_term=tripadvisor
var addAffiliateParameter = function () {
    var affiliateParam = '';
    affiliateParam += checkParameter('coopid');
    affiliateParam += checkParameter('utm_source');
    affiliateParam += checkParameter('utm_medium');
    affiliateParam += checkParameter('utm_campaign');
    affiliateParam += checkParameter('utm_content');
    affiliateParam += checkParameter('utm_term');
    affiliateParam += checkParameter('TAprice');
    return affiliateParam;
};

var checkParameter = function (name) {
    var value = elchspuckeTuicomService.getParameterByName(name);
    if (value) {
        return '&' + name + '=' + value;
    }
    return '';
};

var getChildAges = function(ages) {

    if (!ages || typeof ages !== 'string' || ages.length < 1) {
        return [];
    }

    let childAges = [];
    const childAgesArray = ages.split(';');

    for (let i=0; i < childAgesArray.length; i++) {
        childAges.push(parseInt(childAgesArray[i], 10));
    }
    return childAges;
};

var getChildren = function(children) {
    let travellers = [];
    let childrenTravellers;

    if (!children || typeof children !== 'string') {
        return travellers;
    }

    if (children.length === 0) {
        return travellers.push({children:[]});
    }

    if (children.length > 0) {

        childrenTravellers = children.split(',');

        for (let i=0; i < childrenTravellers.length; i++) {
            travellers.push({children: getChildAges(childrenTravellers[i])});
        }
    }
    return travellers;
};

var mapAdultsAndChildsToIBETravellers = (adults, children) => {

    let travellers = [];
    let adultsTravellers;
    let childrenTravellers = getChildren(children);

    if (!adults) {
        return travellers;
    }

    adultsTravellers = adults.split(',');

    for (let i=0; i < adultsTravellers.length; i++) {
        travellers.push({adults: parseInt(adultsTravellers[i], 10), children: childrenTravellers[i] ? childrenTravellers[i].children : []});
    }

    return travellers;
};

var mapHotelCardsParametersToIBEFilter = function(parameters) {

    let filters = {};

    if (parameters.searchScope) {
        filters.searchScope = parameters.searchScope.toUpperCase();
    }

    if (parameters.departureDate) {
        filters.startDate = new Date(parameters.departureDate);
    }

    if (parameters.returnDate) {
        filters.endDate = new Date(parameters.returnDate);
    }

    if (parameters.durations) {
        filters.duration = parameters.durations;
    }

    if (parameters.boardTypes) {
        filters.boardTypes = parameters.boardTypes;
    }

    if (parameters.roomTypes) {
        filters.roomTypes = parameters.roomTypes;
    }

    if (parameters.departureAirportCodes) {
        filters.departureAirports = parameters.departureAirportCodes;
    }

    if (parameters.adults && parameters.childs) {
        filters.travellers = mapAdultsAndChildsToIBETravellers(parameters.adults, parameters.childs);
    }

    return filters;
};

var hotelCardsCottonBall = function(cardAttributes, scope) {
    if (!scope) {
        scope = 'default';
    }

    window.tuiCottonBall.broadcast('hotel-cards', 'Register new scope.', {scope: scope});
    window.tuiCottonBall.pleaseTakeCareOf('hotel-cards', scope, 'Load cards configuration.', cardAttributes);
};

function showHotelcardsOnPTH4() {
    return window.hasOwnProperty('tuiComponentToggleHotelCardsOnPTH4') ? window.tuiComponentToggleHotelCardsOnPTH4 : true;
}

var checkIbeSearchResultData = function (ibeSearchResultData) {
    if (ibeSearchResultData && ibeSearchResultData.stepNumber && ibeSearchResultData.searchScope &&
        ibeSearchResultData.data && ibeSearchResultData.data.hotel && ibeSearchResultData.data.hotel.giataId) {
        return true;
    }
    return false;
};

/**
 * Find a hotel cards node by given id and remove it if parameter toBeRemoved is true
 * @param {string} id
 * @param {boolean} toBeRemoved
 * @returns {Element}
 */
function findHotelCardsNode(id, toBeRemoved) {
    var hotelCardsNode = document.querySelector('.tui-component.tui-component-hotel-cards[data-id="' + id + '"]');

    if (hotelCardsNode && toBeRemoved === true) {
        elchspuckeTuicomService.removeNode(hotelCardsNode.parentNode);
    }

    return hotelCardsNode;
}

var buildHotelCardsDiv = function (id, cardType, pageName, eventScope) {
    var div = document.createElement('div');
    div.setAttribute('class', 'tui-container-fluid');
    div.setAttribute('style', 'margin-bottom:20px;');

    var cardsDiv = document.createElement('div');
    cardsDiv.setAttribute('class', 'tui-component tui-component-hotel-cards tui-hotel-cards');
    if (id) {
        cardsDiv.setAttribute('data-id', id);
    }
    cardsDiv.setAttribute('data-tenancy', 'TUIAT');
    cardsDiv.setAttribute('data-lang', 'de_AT');
    if (cardType) {
        cardsDiv.setAttribute('data-card-type', cardType);
    }
    cardsDiv.setAttribute('data-currency-format', 'germanFormat');
    cardsDiv.setAttribute('data-toggle-board-type', 'true');
    cardsDiv.setAttribute('data-toggle-room-type', 'true');
    cardsDiv.setAttribute('data-event-scope', eventScope);
    cardsDiv.setAttribute('data-static-url-parameters', '&contentid=HOTELCARDS_' + pageName);
    div.appendChild(cardsDiv);

    return div;
};

var getCardAttributesFromUtag = function () {
    var coopid =  elchspuckeTuicomService.getParameterByName('coopid');
    var tripadvisorPrice = elchspuckeTuicomService.getParameterByName('TAprice');
    var trivagoPrice = elchspuckeTuicomService.getParameterByName('trv_dp');
    var startDate = elchspuckeTuicomService.getParameterByName('startDate');
    var endDate = elchspuckeTuicomService.getParameterByName('endDate');
    var durationString = elchspuckeTuicomService.getParameterByName('duration');
    var adults = elchspuckeTuicomService.getParameterByName('adults');
    var childsString = elchspuckeTuicomService.getParameterByName('childs');
    var departureAirports = elchspuckeTuicomService.getParameterByName('departureAirports');

    var hotelCardAttributes = {
        id: 'id',
        tenancy: 'TUIAT',
        lang: 'de_AT',
        priceType: 'PER_PERSON',
        currencyFormat: 'germanFormat'
    };

    if (coopid) {
        if (coopid.match(/AFF_TUI_TRIPADVISOR/)) {
            hotelCardAttributes.metasearcherName = 'tripAdvisor';
            hotelCardAttributes.metasearcherPrice = tripadvisorPrice;
            hotelCardAttributes.variant = 'logo';
        } else if (coopid.match(/AFF_TUI_TRIVAGO/)) {
            hotelCardAttributes.metasearcherName = 'trivago';
            hotelCardAttributes.metasearcherPrice = trivagoPrice;
            hotelCardAttributes.variant = 'logo';
        }
    }

    if (window.utag_data && window.utag_data['hotel_giataid']) {
        hotelCardAttributes.filterGiataCodes = [window.utag_data['hotel_giataid']];
    }

    if (!isNaN(Date.parse(startDate))) {
        hotelCardAttributes.searchDepartureDate = startDate;
    }

    if (!isNaN(Date.parse(endDate))) {
        hotelCardAttributes.searchReturnDate = endDate;
    }

    if (durationString) {
        var duration = [];
        if (durationString === 'exact') {
            var exactDuration = (Date.parse(endDate) - Date.parse(startDate)) / (1000 * 60 * 60 * 24);
            if (exactDuration > 0 || exactDuration <= 90) {
                duration.push(exactDuration);
            }
        } else {
            var durationArr = durationString.split('-');
            for (var index = 0; index < durationArr.length; index++) {
                if (durationArr[index].length > 0 && !isNaN(durationArr[index])) {
                    duration.push(durationArr[index]);
                }
            }
        }
        hotelCardAttributes.searchDuration = duration;
    }

    if (adults) {
        hotelCardAttributes.searchAdults = adults;
    }
    if (childsString) {
        hotelCardAttributes.searchChilds = childsString;
    }
    if (departureAirports) {
        hotelCardAttributes.searchDepartureAirportCodes = departureAirports.split(';');
    }

    return hotelCardAttributes;
};

function injectUpsellTeaser(ibeSearchResultData) {
    if (ibeSearchResultData.stepNumber === 4 && ibeSearchResultData.searchScope === 'HOTEL') {
        var pageName = elchspuckeTuicomService.getPageName();
        var hotelCardsId = 'upsellTeaser';
        var eventScope = 'upsellTeaser';
        var teaserNode = findHotelCardsNode(hotelCardsId, false);

        var hotelOfferTableHeaderElement = document.querySelector('#tuiViewSort');
        var noOfferElement = document.querySelector('.no-r');
        if (hotelOfferTableHeaderElement || noOfferElement) {

            var div = buildHotelCardsDiv(hotelCardsId, 'opposite-search-scope-image-left', pageName, eventScope);

            if (!teaserNode) {
                if (noOfferElement) {
                    //No results page
                    elchspuckeTuicomService.insertBefore(div, noOfferElement);
                } else if (window.utag_data.hasOwnProperty('upselling_hotelcard_position_in_table')) {
                    elchspuckeTuicomService.insertInPT3AtPosition(div, window.utag_data['upselling_hotelcard_position_in_table']);
                } else {
                    elchspuckeTuicomService.insertBefore(div, hotelOfferTableHeaderElement);
                }

                window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
            }

            var upsellTeaserAttributes = getCardAttributesFromUtag();
            upsellTeaserAttributes.id = hotelCardsId;
            upsellTeaserAttributes.searchScope = 'PACKAGE';
            upsellTeaserAttributes.filterGiataCodes = [ibeSearchResultData.data.hotel.giataId];
            upsellTeaserAttributes.staticUrlParameters = '&contentid=HC_UPSELL_' + ibeSearchResultData.data.hotel.giataId;
            hotelCardsCottonBall(upsellTeaserAttributes, eventScope);
        }
    }
}

var injectHotelCardsTeaserIntoPTH4 = function (apiUrl, ibeSearchResultData) {
    if (showHotelcardsOnPTH4() && checkIbeSearchResultData(ibeSearchResultData)) {
        injectUpsellTeaser(ibeSearchResultData);
    }
};

function buildCampaignData(data, campaigns) {
    var result = {};
    result.instanceId = data.instanceId;
    result.campaignMembers = [];
    var giataIds = data.hotels.map(hotel => hotel.giataId);
    var cardsLanguage = elchspuckeTuicomService.getPageLanguage();

    campaigns.sort((a,b) => (a.campaignConfiguration.priority > b.campaignConfiguration.priority) ? 1 : ((b.campaignConfiguration.priority > a.campaignConfiguration.priority) ? -1 : 0));

    campaigns.forEach(function (campaign) {
        var campaignConfiguration = campaign.campaignConfiguration;
        if (campaignConfiguration && new Date(data.searchParameter.departureDate) < new Date(campaignConfiguration.offersSpecification.endDate) && new Date(data.searchParameter.returnDate) > new Date(campaignConfiguration.offersSpecification.startDate)) {
            if (giataIds !== null && Array.isArray(giataIds)) {
                var campaignTranslation = campaignConfiguration.languages.find(function (translation) {
                    return translation.shortName === cardsLanguage;
                });
                giataIds.forEach(function (giataId) {
                    var campaignData = campaignConfiguration.hotels.find(function (hotel) {
                        return hotel.giataId === giataId && Array.isArray(hotel.searchScopes) && hotel.searchScopes.indexOf(data.searchParameter.searchScope) >= 0;
                    });
                    var layout = campaignConfiguration.layout || {};
                    if (campaignData && result.campaignMembers.map(member => member.giata).indexOf(giataId) < 0) {
                        result.campaignMembers.push({
                            giata: campaignData.giataId,
                            campaignIcon: layout.alternativeCampaignIcon,
                            campaignLabel: campaignData.tag? campaignTranslation.translations.tagSpecific[campaignData.tag].label:campaignTranslation.translations.label,
                            campaignDescription: campaignTranslation.translations.toolTip,
                            campaignStyle: 'primary',
                            campaignBackground: layout.background,
                            campaignFontColor: layout.text
                        });
                    }
                });
            }
        }
    });
    window.tuiCottonBall.broadcast('hotel-cards', 'Here is your campaign data.', result);
}

const getCardsPriceType = (data) => {
    return data.showTotalPrice ? 'TOTAL' : 'PER_PERSON';
};

const updateCards = (data) => {

    if (data) {
        const priceType = getCardsPriceType(data);
        const hotelCardAttributes = {
            priceType
        };
        window.tuiCottonBall.broadcast('hotel-cards', 'Load cards configuration.', hotelCardAttributes);
    }
};

export {
    buildUrlsCallback,
    getTUICOM_PTH_URL,
    injectHotelCardsTeaserIntoPTH4,
    buildCampaignData,
    addAffiliateParameter,
    checkParameter,
    mapHotelCardsParametersToIBEFilter,
    mapAdultsAndChildsToIBETravellers,
    getChildren,
    updateCards,
};
