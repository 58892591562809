import globalConfig from './config';
import LoadCss from './load-css';
let registeredConfig = {};

const register = (components, profileConfig) => {
    registeredConfig = {
        ...globalConfig,
        ...profileConfig
    }
    const modules = [];
    const blacklist = registeredConfig.getComponentBlacklist();
    const callDirectly = ['dataDog', 'PolyfillService', 'IbeFeatureToggles'];

    for (const componentName in components) {
        const component = components[componentName];

        if (blacklist.indexOf(componentName) > -1) {
            continue;
        }

        try {
            if (callDirectly.indexOf(componentName) != -1) {
                component.call(component, registeredConfig);
            } else {
                modules.push(component.bind(component, registeredConfig));
            }
        } catch(error) {
            const err = new Error(`[Mojo]${componentName}:  ${error}`);
            // eslint-disable-next-line no-console
            console.error(err);
        }

    }

    LoadCss(registeredConfig);

    mojito(modules);
};

const mojito = function(registerdComponents) {
    if (document.readyState === 'complete'
        && window.tuiCottonBall
        && typeof Promise === 'function'
        && typeof fetch === 'function'
    ) {
        const componentPromises = [];

        for (let componentIndex = 0; componentIndex < registerdComponents.length; componentIndex++) {
            const component = registerdComponents[componentIndex];
            if (typeof component === 'function') {
                var componentPromise = new Promise(
                    function(resolve){
                        return resolve(component());
                    }
                ).catch(function(err) {
                    // we don't have any means of detecting these errors other than logging to the console atm.
                    // log this to logging component in the future
                    console.error(err, '@ Component:', component); // eslint-disable-line no-console
                });
                componentPromises.push(componentPromise);
            }
        }

        Promise.all(componentPromises)
            .then((componentNames) => {
                let fireEventToRegisteredComponents = function(c, scope, eventName, data) {
                    for (let componentIndex = 0; componentIndex < componentNames.length; componentIndex++) {
                        const componentName = componentNames[componentIndex];
                        if (typeof componentName === 'string') {
                            window.tuiCottonBall.pleaseTakeCareOf(componentName, scope, eventName, data);
                        }
                    }
                };

                window.tuiCottonBall.cuddleMeIf('IBE', '*', 'Finished initializing the angular app.', fireEventToRegisteredComponents);
                window.tuiCottonBall.cuddleMeIf('IBE', '*', 'IBE search result rendered.', fireEventToRegisteredComponents);
                window.tuiCottonBall.cuddleMeIf('IBE', '*', 'The search parameters did change.', fireEventToRegisteredComponents);
                window.tuiCottonBall.cuddleMeIf('IBE', '*', 'IBE checkout page rendered.', fireEventToRegisteredComponents);
                window.tuiCottonBall.cuddleMeIf('IBE', '*', 'IBE checkout confirmation page rendered.', fireEventToRegisteredComponents);
                window.tuiCottonBall.cuddleMeIf('IBE', '*', 'IBE checkout price change rendered.', fireEventToRegisteredComponents);
                window.tuiCottonBall.cuddleMeIf('Blackbird', '*', 'Blackbird rendered.', fireEventToRegisteredComponents);
                window.tuiCottonBall.cuddleMeIf('IBE', '*', 'IBE search result pagination', fireEventToRegisteredComponents);
                window.tuiCottonBall.cuddleMeIf('IBE', '*', 'The view settings did change.', fireEventToRegisteredComponents);
                window.tuiCottonBall.cuddleMeIf('IBE-compact-search', '*', 'search result rendered.', fireEventToRegisteredComponents);
            });
    } else {
        setTimeout(mojito.bind(this, registerdComponents), 150);
    }
};

const getConfig = () => {
    return registeredConfig;
}

export {
    mojito as default,
    register,
    getConfig
};
