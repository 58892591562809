import TravelMapService from './service';
import * as elchspuckeTuicomService from "../../elchspucke-tuicom.service";
import {
    isTravelPeriodWithinCampaignTravelPeriod,
    isValidCampaignDuration
} from '../../campaign/2.1.2/service';
import { injectMapConsent } from '../../maps-consent/1.0.0/mojo';

if (window.tuiCottonBall) {
    window.tuiCottonBall.cuddleMeIf('travel-map', '*', 'track mapbutton click', (data) => {
        elchspuckeTuicomService.trackEvent('feature', 'map', 'open', data.variant);
    });
}

function getAvailableCampaign(ibeResultData, ibeSearchData, campaignData) {
    const campaignObject = {
        campaignName: false
    };
    const now = new Date();
    const searchScope = ibeResultData.searchScope;
    const travelPeriod = {
        duration: ibeSearchData.filters.duration,
        startDate: ibeSearchData.filters.startDate,
        endDate: ibeSearchData.filters.endDate
    };
    const childAges = [];
    ibeSearchData.filters.travellers.forEach(room => childAges.push(room.children))
    
    if ((elchspuckeTuicomService.getPageLanguage() !== 'de' && elchspuckeTuicomService.getPageLanguage() !== 'fr') || ibeResultData.stepNumber !== 2) {
        return campaignObject;
    }

    let allAvailableCampaigns = campaignData.filter(campaign => {
        const configuration = campaign.campaignConfiguration;
        const campaignStart = new Date(configuration.campaignPeriod.startDate);
        const campaignEnd = new Date(configuration.campaignPeriod.endDate);
        const minNumberOfChildren = configuration.offersSpecification.travellers.children.minimumCount || 0;
        const minChildAge = configuration.offersSpecification.travellers.children.minimumAge || 0;
        const maxChildAge = configuration.offersSpecification.travellers.children.maximumAge || 17;

        const validChildAges = childAges.filter(age => minChildAge <= age && maxChildAge >= age);

        return (
            configuration.active &&
            configuration.showMap &&
            configuration.priority &&
            minNumberOfChildren <= validChildAges.length &&
            now > campaignStart &&
            now < campaignEnd &&
            isTravelPeriodWithinCampaignTravelPeriod(travelPeriod, configuration.offersSpecification) &&
            isValidCampaignDuration(travelPeriod, configuration.offersSpecification) &&
            TravelMapService.isConfiguredForSearchScope(searchScope, configuration.offersSpecification) &&
            TravelMapService.showMapInRegion(ibeResultData.response.currentRegion, searchScope, configuration.destinations)
        );
    });

    allAvailableCampaigns = allAvailableCampaigns.sort((a, b) => a.campaignConfiguration.priority - b.campaignConfiguration.priority);

    if (allAvailableCampaigns.length) {
        return allAvailableCampaigns[0];
    }

    return campaignObject;
}


function getEventData(data, ibeSearchData) {
    function cb(component, scope, event, url) {
        if (url.indexOf('/') > 0) {
            url = '/' + url;
        }
        if (typeof data.processLink === 'function') {
            data.processLink(window.location.origin + url + '&contentid=travel-map_' + data.mapScope);
        }
    }

    function getFilters(data, ibeSearchData) {
        // https://jira.tuigroup.com/browse/BUG-16880 quick fix: not use ibeSearchData
        if (typeof ibeSearchData === 'undefined' || !ibeSearchData.hasOwnProperty('thisIsNotAProperty_BUG-16880')) {
            return {
                adults: data.queryParameters.adults,
                startDate: new Date(data.queryParameters.startDate),
                endDate: new Date(data.queryParameters.endDate),
                duration: data.queryParameters.duration,
                searchScope: data.queryParameters.searchScope
            };
        } else {
            return ibeSearchData.filters;
        }
    }

    return {
        callback: cb,
        destination: {
            giataId: data.hotelInformation.giataCode,
            name: data.hotelInformation.hotelName
        },
        filters: getFilters(data, ibeSearchData)
    };

}

export default function travelMap(config) {
    const tenant = config.getTenant();

    if (window.tuiCottonBall) {
        window.tuiCottonBall.cuddleMeIf('travel-map', '*', 'IBE search result rendered.', (component, scope, event, ibeResultData) => {
            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (a, b, c, ibeSearchData) => {
                window.tuiCottonBall.broadcast('campaign', 'Call me back with your campaign configuration.', campaignData => {

                    let travelMapNode = document.querySelector('.tui-component.tui-component-travel-map');
                    if (travelMapNode) {
                        elchspuckeTuicomService.removeNode(travelMapNode);
                    }

                    const campaginObject = getAvailableCampaign(ibeResultData, ibeSearchData, campaignData);

                    if (campaginObject.campaignName) {
                        injectMapConsent({
                            config,
                            nodeToRenderIn: document.querySelector('section#tuiResult'),
                            onConsentApprove: () => {
                                TravelMapService.insertTravelMap(tenant, campaginObject);
                            }
                        });

                        if(window.sessionStorage.getItem('tui-maps-consent') === '1') {
                            TravelMapService.insertTravelMap(tenant, campaginObject);
                        }
                    }
                });
            });
        });

        window.tuiCottonBall.cuddleMeIf('travel-map', '*', 'generate PT4 Link.', (component, scope, event, data) => {
            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (a, b, c, ibeSearchData) => {
                window.tuiCottonBall.broadcast('IBE', 'generate URL', getEventData(data, ibeSearchData));
            });
        });
    }

    return 'travel-map';
}
