import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service.js';
import { loadScripts } from '../../util.js';
import * as Service from './service.js';
import * as searchData from './search-data';

export default function (config) {

    const parentNode = document.getElementById('travel-information-container');

    if (parentNode) { // STORY-17422
        parentNode.classList = 'travel-information-container';
        buildComponentElements(config, parentNode);
    }

    return 'travel-information';
}

const buildComponentElements = (config, parentNode) => {
    const settings = {
        language: elchspuckeTuicomService.getPageLanguage(),
        uiUrl: '',
        tenantSearchParam: 'tui.at'
    };
    
    loadScripts([
        `${config.getAWSCdnUrl()}travel-information/current/tui-travel-information-navbar.js`,
        `${config.getAWSCdnUrl()}travel-information/current/tui-travel-information-destination.js`,
        `${config.getAWSCdnUrl()}travel-information/current/tui-travel-information-return.js`
    ]).then(function () {

        const searchNode = document.createElement('section');
        searchNode.classList = 'search-panel';
        searchNode.innerHTML = Service.createSearchFormTag(settings);

        const travelInformationNode = document.createElement('section');
        travelInformationNode.classList = 'travel-information-panel'
        travelInformationNode.innerHTML = Service.createTravelInformationTag();

        parentNode.appendChild(searchNode);
        parentNode.appendChild(travelInformationNode);

        document.tui = document.tui || {};
        document.tui.uiUrl = settings.uiUrl;
        document.tui.tenantSearchParam = settings.tenantSearchParam;
        document.tui.searchParameters = searchData.parameters;

        // TRAVEL-INFORMATION

        Service.scriptTravelInformation();

        // SEARCH-FORM

        const urlParameters = {
            get searchFormParameters() {
                const params = new URL(document.location).searchParams;
                const getArrayParam = (name, separator = ';') => params.get(name)?.split(separator);

                return {
                    locale: document.tui.locale,
                    countries: {
                        departures: {
                            selected: getArrayParam('return')?.map((code) => ({ code }))
                        },
                        destinations: {
                            selected: getArrayParam('destination')?.map((code) => ({ code }))
                        }
                    },
                    flexible: {
                        vaccinations: {
                            selected: getArrayParam('vaccination')?.map((key) => ({ key }))
                        }
                    }
                };
            },
            set searchFormParameters(value) {
                const url = new URL(document.location);
                const params = url.searchParams;
                const setParam = (name, value) => {
                    if (value) {
                        params.set(name, value);
                    } else {
                        params.delete(name);
                    }
                };

                setParam('return', value.countries.departures.selected?.map(({ code }) => code).join(';'));
                setParam('destination', value.countries.destinations.selected?.map(({ code }) => code).join(';'));
                setParam('vaccination', value.flexible.vaccinations.selected?.map(({ key }) => key).join(';'));

                const newUrl = url.toString();
                if (newUrl !== document.location.toString()) {
                    window.history.pushState(null, '', newUrl);
                }
            }
        };
        
        loadScripts([
            `${config.getAWSCdnUrl()}search-form/v2/search-form.js`,
            `${config.getAWSCdnUrl()}search-country/current/search-country.js`,
            `${config.getAWSCdnUrl()}search-flexible/current/search-flexible.js`,
            `${config.getAWSCdnUrl()}search-button/current/search-button.js`
        ]).then(function () {
            const urlParams = urlParameters.searchFormParameters;
            const searchformParameters = {
                locale: urlParams.locale || searchData.locale,
                countries: {
                    departures: {
                        selected:
                            urlParams.countries?.departures?.selected || document.tui.searchParameters._selectedCountriesDepartures,
                            suggestions: document.tui.searchParameters._suggestedCountriesDepartures,
                            list: document.tui.searchParameters._setCountriesDataDepartures
                    },
                    destinations: {
                        selected:
                            urlParams.countries?.destinations?.selected || document.tui.searchParameters._selectedCountriesDestintaions,
                            suggestions: document.tui.searchParameters._suggestedCountriesDestintaion
                    }
                },
                flexible: {
                    vaccinations: {
                        selected:
                            urlParams.flexible?.vaccinations?.selected || document.tui.searchParameters._selectedVaccinations,
                            suggestions: document.tui.searchParameters._suggestedVaccinations,
                            list: document.tui.searchParameters._setCountriesDataVaccinations
                    }
                }
            };

            const searchForm = document.querySelector('tui-search-form');
            searchForm.setFormData(searchformParameters);
            addSearchFormListener(searchForm);
        });

        const performSearch = (searchFormParameters) => {
            document.dispatchEvent(
                new CustomEvent('tui-search-form.updated', {
                    detail: searchFormParameters
                })
            );
        };

        const addSearchFormListener = (searchForm) => {
            searchForm.addEventListener('tui-search-form.submit', (event) => {
                urlParameters.searchFormParameters = event.detail;
                performSearch(event.detail);
            });
        };
    });
};