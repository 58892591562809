"use strict";
import * as HotelCardsService from './service';

export default function (config) {
    const plattformUrl = config.getPlattformHost();
    const apiUrl = config.getApiUrl();

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'Someone pushed my button.', function (component, scope, event, data) {
        HotelCardsService.getTUICOM_PTH_URL(plattformUrl, component, scope, event, data);
    });

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'Build URLS', HotelCardsService.buildUrlsCallback.bind(this, plattformUrl));

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'IBE search result rendered.', function(component, scope, event, data){
        if (data.stepNumber === 4) {
            HotelCardsService.injectHotelCardsTeaserIntoPTH4(apiUrl, data);
        }
    });

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'Tell me about campaigns.', function (component, scope, event, data) {
        window.tuiCottonBall.pleaseTakeCareOf('campaign', scope, 'Call me back with your campaign configuration.', HotelCardsService.buildCampaignData.bind(this, data));
    });

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'The view settings did change.', function(component, scope, event, data){
        HotelCardsService.updateCards(data);
    });

    return 'hotel-cards';
}
