export default function (plattformUrl) {

    var plattformUrlsCallback = function() {
        window.tuiCottonBall.pleaseTakeCareOf('travelexplorer', 'window', 'This is my plattform url.', {plattformUrl: window.location.origin});
    };

    window.tuiCottonBall.cuddleMeIf('travelexplorer', '*', 'Ask for plattform url.', plattformUrlsCallback.bind(this, plattformUrl));

    window.tuiCottonBall.cuddleMeIf('travelexplorer', '*', 'Track suggest event.', function(a, b, c, data) {
        var linkLabel = data.numberOfSuggestResults + ' + ' + data.searchTerm;
        if (window.utag && window.utag.link && typeof(window.utag.link) === 'function' ) {
            window.utag.link({ link_type: 'feature', link_category: 'freetextsearch', link_action: 'suggest', link_label: linkLabel });
        }
    });

    window.tuiCottonBall.cuddleMeIf('travelexplorer', '*', 'Track suggestion selected event.', function(a, b, c, data) {
        var linkLabel = data.positionWithinCategory + ' + ' + data.category + ' + ' + data.searchTerm + ' + ' + data.selectedItemName;
        if (window.utag && window.utag.link && typeof(window.utag.link) === 'function' ) {
            window.utag.link({ link_type: 'feature', link_category: 'freetextsearch', link_action: 'select', link_label: linkLabel });
        }
    });

}
