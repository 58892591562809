import * as mojoService from './elchspucke-tuicom.service';

/**
 * Yields the difference between both given dates in days. It's best to compare dates with same times.
 * @param  {Date} earlierDate
 * @param  {Date} laterDate
 * @return {int}             the difference in days, rounded (more than 12 hours will represent a day)
 */
const daysBetween = (earlierDate, laterDate) => {
    if (
        !earlierDate ||
        !laterDate ||
        typeof earlierDate.getTime !== 'function' ||
        typeof laterDate.getTime !== 'function'
    ) {
        return false;
    }
    //Get 1 day in milliseconds
    const one_day = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    const date1_ms = earlierDate.getTime();
    const date2_ms = laterDate.getTime();

    // Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms;

    // Convert back to days and return
    return Math.round(difference_ms / one_day);
};

/**
 * Yields the date string in the form 'YYYY-MM-DD' to cope with JavaScripts local timezone fuckup. The offset of the local timezone will be added to the date to get the correct day the date was created with. Thus if a date was created like new Date('2018-12-24') this method will yield the exact same string.
 * Attention! This only works for dates that were created with a time but without time zone, as JS then creates a date object in the local timezone with that time instead of 00:00:00 in UTC time and recalculating it to the local time with the timezone offset (and then loosing the original time zone information).
 * We use this, as we get a Date from the 'request IBE state'-event, that is created using something like: new Date('2018-12-24T00:00:00'). This shall represent the user input of the day 24. December 2018. It is created with the local browser timezone, though it is just a day without time information.
 * We need it later to compare dates and to search for offers. In the backend we only work with UTC dates or dates with explizit time zones. JavaScript dates can't provide this safely, so we need a method to get the day that was meant by the user out of the already manipulated local date object.
 * @param  {[type]} inputDate   A Date object. This is always local browser time.
 * @return {[type]}      [description]
 */
const extractDateStringWithResetTimezone = (inputDate) => {
    if (!inputDate || typeof inputDate.getTimezoneOffset !== 'function' || typeof inputDate.getTime !== 'function') {
        return false;
    }

    // reset timezone offset to normalize date to UTC for String extraction
    const timezoneOffset = inputDate.getTimezoneOffset() * 60000;

    // we add the offset shifting the date in UTC to the same date / time
    // this produces a different date, but all we want is to get the correct day as a string
    // which is impossible as all string methods normalize dates to UTC before retrieving data
    // and we would always end up with the string of the shifted date
    const utcDateWithSameDay = new Date(inputDate.getTime() - timezoneOffset);

    const isoString = utcDateWithSameDay.toISOString();

    if (!isoString) {
        return false;
    }

    const isoParts = isoString.split('T');

    return isoParts && isoParts.length > 0 ? isoParts[0] : false;
};

/**
 *
 * @param stringToClean
 * @param lastChar
 * @returns {string}
 */
const cleanStringFromTrailingChar = (stringToClean, lastChar) => {
    if (!stringToClean || typeof stringToClean !== 'string') {
        return '';
    }
    const stringLen = stringToClean.length - 1;
    const cleanString =
        stringToClean.charAt(stringLen) === lastChar ? stringToClean.substr(0, stringLen) : stringToClean;
    return cleanString;
};

const domReady = (callback) => {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', () => {
            callback();
        });
    } else {
        callback();
    }
};

const readyStateComplete = (callback) => {
    if (document.readyState === 'complete') {
        callback();
    } else {
        document.addEventListener('readystatechange', () => {
            if (document.readyState === 'complete') {
                callback();
            }
        });
    }
};

const deferTillTealium = (method, retries) => {
    if (typeof window.utag === 'object') {
        method();
    } else {
        if (retries && retries >= 0) {
            setTimeout(() => {
                deferTillTealium(method, retries - 1);
            }, 150);
        }
    }
};

const deferTilljQuery = (method, retries) => {
    if (typeof window.jQuery === 'function') {
        method();
    } else {
        if (retries >= 0) {
            setTimeout(() => {
                deferTilljQuery(method, retries - 1);
            }, 200);
        }
    }
};

const deferTillScope = (selector, method, retries) => {
    const $element = $(selector);
    let scope = null;

    if ($element && $element.length != 0) {
        if ($element.scope) {
            scope = $element.scope();
        }
    }

    if ($element && scope) {
        method(scope);
    } else {
        if (retries >= 0) {
            setTimeout(() => {
                deferTillScope(selector, method, retries - 1);
            }, 200);
        }
    }
};

const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const createPT4SearchServiceSearchModelForIbeState = function (ibeState) {
    //check ibeState
    if (!ibeState || typeof ibeState !== 'object' || Object.keys(ibeState).length <= 0) {
        return false;
    }

    let searchModel = {};

    // check filters object
    if (ibeState.filters || typeof ibeState.filters === 'object') {
        if (ibeState.filters.searchScope && typeof ibeState.filters.searchScope === 'string') {
            searchModel.searchscope = ibeState.filters.searchScope;
        }

        if (ibeState.filters.operators || Array.isArray(ibeState.filters.operators)) {
            searchModel.touroperators = ibeState.filters.operators;
        }

        if (ibeState.filters.earlyBird) {
            searchModel.hotelattributes = ['EARLY-BIRD'];
        }

        if (ibeState.filters.nightSavings) {
            if (searchModel.hotelattributes) {
                searchModel.hotelattributes.push('NIGHT-SAVINGS');
            } else {
                searchModel.hotelattributes = ['NIGHT-SAVINGS'];
            }
        }

        if (ibeState.filters.maxPrice && ibeState.filters.maxPrice > 0) {
            searchModel.maxprice = ibeState.filters.maxPrice;
        }

        if (ibeState.filters.minPrice && ibeState.filters.minPrice > 0) {
            searchModel.minprice = ibeState.filters.minPrice;
        }

        if (ibeState.filters.duration && ibeState.filters.startDate && ibeState.filters.endDate) {
            let startDateObject = ibeState.filters.startDate;
            let endDateObject = ibeState.filters.endDate;

            if (typeof ibeState.filters.startDate === 'string') {
                startDateObject = new Date(ibeState.filters.startDate);
            }
            if (typeof ibeState.filters.endDate === 'string') {
                endDateObject = new Date(ibeState.filters.endDate);
            }
            let durations = mojoService.goAndHandlePTH4Durations(
                ibeState.filters.duration,
                startDateObject,
                endDateObject
            );

            searchModel.startdate = extractDateStringWithResetTimezone(startDateObject);
            searchModel.enddate = extractDateStringWithResetTimezone(endDateObject);
            searchModel.duration = durations;
        }

        if (ibeState.filters.departureMaxTime && typeof ibeState.filters.departureMaxTime === 'string') {
            searchModel.departuremaxtime = ibeState.filters.departureMaxTime + ':00Z';
        }

        if (ibeState.filters.departureMinTime && typeof ibeState.filters.departureMinTime === 'string') {
            searchModel.departuremintime = ibeState.filters.departureMinTime + ':00Z';
        }

        if (ibeState.filters.returnMaxTime && typeof ibeState.filters.returnMaxTime === 'string') {
            searchModel.returnmaxtime = ibeState.filters.returnMaxTime + ':00Z';
        }

        if (ibeState.filters.returnMinTime && typeof ibeState.filters.returnMinTime === 'string') {
            searchModel.returnmintime = ibeState.filters.returnMinTime + ':00Z';
        }

        if (ibeState.filters.travellers) {
            let rooms = mojoService.goAndHandlePTH4Rooms(
                ibeState.filters.travellers,
                ibeState.filters.roomTypes,
                ibeState.filters.boardTypes,
                ibeState.filters.roomSpecificAttributes
            );

            if (rooms) {
                searchModel.travellers = [];
                searchModel.rooms = [];
                for (let roomKey in rooms) {
                    searchModel.travellers[roomKey] = {};
                    let room = rooms[roomKey];

                    if (room.numberOfAdults !== 0) {
                        searchModel.travellers[roomKey].adults = room.numberOfAdults;
                    }
                    if (room.childAges && room.childAges instanceof Array && room.childAges.length > 0) {
                        searchModel.travellers[roomKey].children = room.childAges;
                    }
                    if (room.roomCodes && room.roomCodes instanceof Array && room.roomCodes.length > 0) {
                        searchModel.rooms[roomKey] = { roomcodes: room.roomCodes };
                    }
                    if (room.boardCodes && room.boardCodes instanceof Array && room.boardCodes.length > 0) {
                        if (searchModel.rooms[roomKey]) {
                            searchModel.rooms[roomKey].boardcodes = room.boardCodes;
                        } else {
                            searchModel.rooms[roomKey] = { boardcodes: room.boardCodes };
                        }
                    }
                    if (
                        room.roomSpecificAttributes &&
                        room.roomSpecificAttributes instanceof Array &&
                        room.roomSpecificAttributes.length > 0
                    ) {
                        if (searchModel.rooms[roomKey]) {
                            searchModel.rooms[roomKey].roomviewcodes = room.roomSpecificAttributes;
                        } else {
                            searchModel.rooms[roomKey] = { roomviewcodes: room.roomSpecificAttributes };
                        }
                    }
                }
            }
        }

        if (searchModel.searchscope && searchModel.searchscope.toLowerCase() === 'package') {
            if (
                ibeState.filters.airlines &&
                ibeState.filters.airlines instanceof Array &&
                ibeState.filters.airlines.length > 0
            ) {
                searchModel.airlines = ibeState.filters.airlines;
            }
            if (
                ibeState.filters.departureAirports &&
                ibeState.filters.departureAirports instanceof Array &&
                ibeState.filters.departureAirports.length > 0
            ) {
                searchModel.airports = ibeState.filters.departureAirports;
            }
            if (ibeState.filters.maxStopOver != null && !isNaN(ibeState.filters.maxStopOver)) {
                searchModel.stopover = ibeState.filters.maxStopOver;
            }
        }
    }

    // check hotels object
    if (ibeState.hotel || typeof ibeState.hotel === 'object') {
        if (ibeState.hotel.giata) {
            searchModel.giatas = ibeState.hotel.giata;
        }
    }

    return searchModel;
};

/**
 * Provides checks for different pages
 */
const pageIs = {
    /**
     * @returns {boolean} true, if we are on BU1 (/checkout)
     */
    home: () => {
        return window.location.pathname === '/';
    },
    BU1: () => {
        return window.location.href.indexOf('/checkout') > -1 && window.location.href.indexOf('/checkout/ok') === -1;
    },
    BU3: () => {
        return window.location.href.indexOf('/checkout/ok') !== -1;
    },
    PT3: () => {
        const url = window.location.href;
        return url.indexOf('/suchen/hotels/') !== -1 && url.indexOf('searchScope=HOTEL') === -1;
    },
    PT4: () => {
        const url = window.location.href;
        return url.indexOf('/suchen/angebote/') !== -1 && url.indexOf('searchScope=HOTEL') === -1;
    },
    PTH2: () => {
        return window.location.href.indexOf('/suchen/regionen/') !== -1;
    },
    PTH3: () => {
        return window.location.href.indexOf('/suchen/hotels/') !== -1;
    },
    PTH4: () => {
        return window.location.href.indexOf('/suchen/angebote/') !== -1;
    },
    FrenchPTH2: () => {
        return window.location.href.indexOf('/recherche/regions/') !== -1;
    },
    FrenchPTH3: () => {
        return window.location.href.indexOf('/recherche/hotels/') !== -1;
    },
    FrenchPTH4: () => {
        return window.location.href.indexOf('/recherche/offres/') !== -1;
    },
    H3: () => {
        const url = window.location.href;
        return url.indexOf('/suchen/hotels/') !== -1 && url.indexOf('searchScope=HOTEL') !== -1;
    },
    H4: () => {
        const url = window.location.href;
        return url.indexOf('/suchen/angebote/') !== -1 && url.indexOf('searchScope=HOTEL') !== -1;
    }
};

/**
 * Provides checks for different browser capabilities
 */
const browserIs = {
    /**
     * @returns {boolean} true, if the browser is considered to be mobile based on the user agent string
     */
    mobile: () => {
        let isMobile = function (a) {
            // eslint-disable-next-line no-useless-escape
            return (
                /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                    a
                ) ||
                /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                    a.substr(0, 4)
                )
            );
        };
        const browser = navigator.userAgent || navigator.vendor || window.opera;
        return isMobile(browser);
    },
    internetExplorer: () => {
        const navUserAgent = window.navigator.userAgent;
        const isInternetExplorer = /MSIE|Trident/.test(navUserAgent);
        return isInternetExplorer;
    }
};

const getIbeScope = (ctrlName) => {
    var sel = 'div[ng-controller="' + ctrlName + '"]';

    return window.angular && window.angular.element(sel).scope();
};

/**
 * getNestedObject
 * @see https://hackernoon.com/accessing-nested-objects-in-javascript-f02f1bd6387f
 * @param  {Object} nestedObj
 * @param  {Array} pathArr
 * @return {*} the selected object propertie or undefined
 *
 * @example
 * const FooObject = {
 *   personalInfo: {
 *      name: 'fooBar'
 *   }
 * }
 *
 * const foo = getNestedObject(FooObject, ['personalInfo', 'name']);
 */
const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), nestedObj);
};

/**
 * Formats date object YYYY-MM-DD
 * @param {Date} date js date object
 * @return {string} date in format YYYY-MM-DD
 */
const formatDate = (date) => {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

/**
 * Determines whether two date ranges overlap
 * @param {Date|number} startA start date A
 * @param {Date|number} endA end date A
 * @param {Date|number} startB start date B
 * @param {Date|number} endB end date B
 * @return {boolean} true if dates are overlapping, otherwise false
 */
const hasDateOverlap = (startA, endA, startB, endB) => {
    return startA <= endB && endA >= startB;
};

/**
 * Returns true if we are on a mobile screen at the time of calling that function
 * and false otherwise.
 *
 * @returns {Boolean}
 */
const isMobileScreen = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width < 481;
};

/**
 * Returns true if we are on a tablet screen at the time of calling that function
 * and false otherwise.
 *
 * @returns {Boolean}
 */
const isTabletScreen = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width < 769;
};

async function loadScripts(scripts, options = {}) {
    function get(src) {
        return new Promise(function (resolve, reject) {
            var el = document.createElement('script');
            el.async = true;
            if (!options.hasOwnProperty('type')) {
                el.type = 'module';
            } else if (options.type !== '') {
                el.type = options.type;
            }
            el.src = src;
            el.addEventListener(
                'load',
                function () {
                    resolve(src);
                },
                false
            );
            el.addEventListener(
                'error',
                function () {
                    reject(src);
                },
                false
            );
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(el);
        });
    }

    const myPromises = scripts.map(async function (script) {
        if (!document.querySelector(`script[src*="${script}"]`)) {
            return await get(script);
        }
    });

    return await Promise.all(myPromises);
}

/**
 * Creates HTML element
 * @param {string} tagName
 * @param {string} classNameElement
 * @param {string} id
 * @return {HTMLElement} created html element
 */
function createHTMLNode(tagName, classNameElement, id, attributes = []) {
    if (!tagName || typeof tagName !== 'string' || tagName === '') {
        return false;
    }
    let htmlNode = document.createElement(tagName);
    if (classNameElement && typeof classNameElement === 'string' && classNameElement !== '') {
        htmlNode.className = classNameElement;
    }
    if (id && typeof id === 'string' && id !== '') {
        htmlNode.id = id;
    }

    attributes.forEach((attribute) => {
        htmlNode.setAttribute(attribute.key, attribute.value);
    });

    return htmlNode;
}

export {
    daysBetween,
    extractDateStringWithResetTimezone,
    cleanStringFromTrailingChar,
    domReady,
    readyStateComplete,
    deferTillTealium,
    deferTilljQuery,
    deferTillScope,
    getRandomInt,
    createPT4SearchServiceSearchModelForIbeState,
    pageIs,
    browserIs,
    getIbeScope,
    getNestedObject,
    formatDate,
    hasDateOverlap,
    isMobileScreen,
    isTabletScreen,
    loadScripts,
    createHTMLNode
};
