import * as HotelOfferCardsService from './service.js';

export default function (config) {
    const plattformUrl = config.getPlattformHost();
    const tenant = config.getTenant();
    const campaignServicePath = config.getAWSApiUrl() + '/campaign-configuration-service/current';

    let hearts = [];
    window.tuiCottonBall.subscribeToAllScopes('hotel-offer-cards', 'I am falling in love with someone else.', function (component, scope, event, data) {
        hearts = data;
        window.tuiCottonBall.broadcast('hotel-offer-cards', 'hearts.updated', { hearts: hearts });
    });

    window.tuiCottonBall.subscribeToAllScopes('hotel-offer-cards', 'search.accepted', function (component, scope, event, data) {
        HotelOfferCardsService.retrieveBanderoles(campaignServicePath, tenant, data.searchParameters, data.setBanderoles);
        data.setHearts(hearts);
    });

    window.tuiCottonBall.subscribeToAllScopes('hotel-offer-cards', 'instance.rendered', function (component, scope, event, data) {
        HotelOfferCardsService.generateCallToActionUrls(plattformUrl, data.searchParameters, data.hotelOffers, data.staticUrlParameters, data.setCallToActionUrls);
    });

    window.tuiCottonBall.subscribeToAllScopes('hotel-offer-cards', 'heart.touched', function (component, scope, event, data) {
        if (data.filled) {
            window.tuiCottonBall.publish('favorites', 'window', 'Add a hotel to favorites.', {giataId: data.hotelOffer.hotel.giataId});
        } else {
            window.tuiCottonBall.broadcast('favorites', 'Someone broke my heart.', {giataId: data.hotelOffer.hotel.giataId});
        }
    });

    window.tuiCottonBall.subscribeToAllScopes('hotel-offer-cards', 'The view settings did change.', function(component, scope, event, data){
        HotelOfferCardsService.updateViewSettings(data);
    });

    return 'hotel-offer-cards';
}
