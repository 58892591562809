import * as ElchspuckeService from '../../elchspucke-tuicom.service';

const translation = {
    TUICOM: {
        de: {
            title: 'Wir beraten Sie gerne unter 0511 87 9898 - 98',
            subline:
                'Unsere TUI Experten freuen sich auf Ihren Anruf und helfen Ihnen gerne, Ihren persönlichen Traumurlaub zu finden.'
        }
    },
    TUIAT: {
        de: {
            title: 'Wir beraten Sie gerne unter 0800 400 202',
            subline:
                'Unsere TUI Experten freuen sich auf Ihren Anruf und helfen Ihnen gerne, Ihren persönlichen Traumurlaub zu finden.'
        }
    },
    TUICH: {
        de: {
            title: 'Wir beraten Sie gerne unter 0848 121 221',
            subline:
                'Unsere TUI Experten freuen sich auf Ihren Anruf und helfen Ihnen gerne, Ihre persönlichen Traumferien zu finden.'
        },
        fr: {
            title: 'Nous vous conseillons volontiers au 0848 808 858',
            subline:
                'Nos experts TUI se réjouissent de votre appel et vous aideront volontiers à trouver les vacances de vos rêves.'
        }
    }
};

const getBannerTag = (bannerData, id) => {
    if (!bannerData && !bannerData.campaignName && !bannerData.targetUrl && !id) {
        return null;
    }
    let html = document.createElement('tui-banner');
    html.className = 'tui-component';
    html.id = id;
    html.dataset.stencil = 'true';
    html.dataset.bannerId = bannerData.bannerId;
    
    html.setAttribute('data-legacy-font', '');
    html.setAttribute('variant', bannerData.variant || 'default');

    if (bannerData.theme) {
        html.setAttribute('data-theme', bannerData.theme);
    } else {
        html.setAttribute('data-theme', 'tui-light');
    }

    if (bannerData.text) {
        html.setAttribute('text', bannerData.text);
    }

    if (bannerData.title) {
        html.setAttribute('headline', bannerData.title);
    }

    if (bannerData.buttonText) {
        html.setAttribute('button-text', bannerData.buttonText);
    }

    if (bannerData.infoText) {
        html.setAttribute('info-text', bannerData.infoText);
    }

    if (bannerData.background) {
        html.setAttribute('background', bannerData.background);
    }

    if (bannerData.bagVariant) {
        html.setAttribute('icon', bannerData.bagVariant);
    }

    if (bannerData.icon) {
        html.setAttribute('icon', bannerData.icon);
    }

    if (bannerData.toggleText) {
        html.setAttribute('toggle-text', bannerData.toggleText);
    }

    if (bannerData.locale) {
        html.setAttribute('locale', bannerData.locale);
    }

    if (bannerData.toggle) {
        html.toggleAttribute('toggle');
    }

    return html;
};

const getBannerIsc = (id, tenant) => {
    const html = document.createElement('div');
    const language = ElchspuckeService.getDomainLocale().split('-')[0];
    const title = translation[tenant][language].title;
    const subline = translation[tenant][language].subline;
    html.id = id;
    html.className = 'phone-banner-isc hidden-xs';
    html.innerHTML = `
        <div class="service-center-info">
            ${title}
            <div class="service-center-info-bottom">
                ${subline}
            </div>
        </div>
    `;

    return html;
};

export { getBannerTag, getBannerIsc };
