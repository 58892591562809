import * as search from './search-data';

const createSearchFormTag = (config) => {
   const {language} = config;

  return `
    <section class="search">
        <tui-search-form
            id="tui-search-form-top"
            class="tui-search-form tui-search-form--hidden columns-3"
            theme="tuiLightCentralRegion"
            type="filter"
            locale="${search.locale}"
            tenant="${search.tenant}"
            traveltype="${search.travelType}"
            submit-on-change="false">

            <!-- Destination -->
            <tui-search-form-field 
            id="tui-search-form-top-destinations"
            locale="${search.locale}"
            heading="${ search[language].destination.heading }" 
            required="${ search[language].destination.errorEmpty }"
            type="countries.destinations.selected"
            resetable="true">
                <tui-search-country slot='view' field-name="destinations" icon="aircraft-up"></tui-search-country>
                <tui-search-country 
                    slot='edit' 
                    field-name="destinations" 
                    icon="aircraft-up" 
                    variant='edit' 
                    heading-region-close="Reiseziele verbergen"
                    heading-selected="${ search[language].destination.selected }"
                    heading-suggestions="${ search[language].destination.suggestions }"
                    placeholder="${ search[language].destination.placeholder }">
                </tui-search-country>
            </tui-search-form-field>

            <!-- departure -->
            <tui-search-form-field 
            id="tui-search-form-top-departure"
            locale="${search.locale}"
            heading="${ search[language].departure.heading }" 
            required="${ search[language].departure.errorEmpty }"
            type="countries.departures.selected"
            resetable="true">
                <tui-search-country slot='view' field-name="departures" icon="aircraft-down"></tui-search-country>
                <tui-search-country 
                    slot='edit' 
                    field-name="departures" 
                    icon="aircraft-down" 
                    variant='edit' 
                    heading-region-close="Land der Abreise verbergen"
                    heading-selected="${ search[language].departure.selected }"
                    heading-suggestions="${ search[language].departure.suggestions }"
                    placeholder="${ search[language].departure.placeholder }">
                </tui-search-country>
            </tui-search-form-field>

            <!-- vaccination -->
            <tui-search-form-field 
            id="tui-search-form-top-vaccination"
            locale="${search.locale}"
            heading="${ search[language].vaccination.heading }" 
            required="${ search[language].vaccination.errorEmpty }"
            type="flexible.vaccinations.selected"
            resetable="true">
                <tui-search-flexible slot='view' field-name="vaccinations" icon="user"></tui-search-flexible>
                <tui-search-flexible 
                    slot='edit' 
                    field-name="vaccinations" 
                    icon="user" 
                    variant='edit' 
                    heading-region-close="Impf- oder Genesenestatus verbergen"
                    heading-selected="${ search[language].vaccination.selected }"
                    heading-suggestions="${ search[language].vaccination.suggestions }"
                    placeholder="${ search[language].vaccination.placeholder }">
                </tui-search-flexible>
            </tui-search-form-field>

            <!-- button -->
            <tui-search-form-field 
            id="tui-search-form-top-button" 
            class="button" 
            locale="${ search.locale }"
            traveltype="${ search.travelType }">
                <tui-search-button slot='view' label="${ search[language].searchButtonText }"></tui-search-button>
                </tui-search-form-field>
        </tui-search-form>
    </section>
  `;  
};

const createTravelInformationTag = () => {
    return `
        <section class="l-container travel-information">
            <tui-travel-information-navbar class="tui-travel-information-page-navbar" theme="tuiLightCentralRegion" tenant="${search.tenant}" locale="${search.locale}" hidden></tui-travel-information-navbar>
            <tui-travel-information-destination class="tui-travel-information-page-destination" theme="tuiLightCentralRegion" tenant="${search.tenant}" locale="${search.locale}" show-overview="true" show-restrictions="true"></tui-travel-information-destination>
            <tui-travel-information-return class="tui-travel-information-page-return" theme="tuiLightCentralRegion" tenant="${search.tenant}" locale="${search.locale}" hidden></tui-travel-information-return>
        </section>
    `;
}

const scriptTravelInformation = () => {
    document.tui = document.tui || {};

    // push the form-fields higher if you cant see the inner content
    document.querySelectorAll('tui-search-form-field').forEach((field) => {
        field.addEventListener('click', () => {
            var fromTopToOpenContent = (document.getElementById('tui-search-form-top').offsetTop  + 550) - window.innerHeight;

            if (window.innerWidth >= 1440 && window.scrollY < fromTopToOpenContent) {
                window.scrollTo(0, fromTopToOpenContent);
            }
        });
    });
    
    document.addEventListener('tui-search-form.rendered', () => {
        setTimeout(() => {
            const searchFormTop = document.getElementById('tui-search-form-top');
            if (searchFormTop) {
                searchFormTop.classList.remove('tui-search-form--hidden');
            }

            const searchFormSkeleton = document.getElementById('travel-information-page-search-skeleton');
            if (searchFormSkeleton) {
                searchFormSkeleton.remove();
            }
        });
    });

    document.addEventListener('tui-search-form.updated', (event) => {
        document.querySelector('tui-travel-information-navbar').style.display = 'block';

        const searchFormData = event.detail;
        document.querySelector('tui-travel-information-navbar').dispatchEvent(
            new CustomEvent('tui-travel-information-navbar.setData', {
                detail: {
                    locale: searchFormData.locale,
                    destination: searchFormData.countries.destinations.selected[0].name,
                    return: searchFormData.countries.departures.selected[0].name
                }
            })
        );

        document.querySelector('tui-travel-information-destination').dispatchEvent(
            new CustomEvent('tui-travel-information-destination.setData', {
                detail: {
                    locale: searchFormData.locale,
                    destination: searchFormData.countries.destinations.selected[0].code,
                    // nationality: searchFormData.countries.nationalities.selected[0].code,
                    vaccination: searchFormData.flexible.vaccinations.selected[0].key
                }
            })
        );

        document.querySelector('tui-travel-information-return').dispatchEvent(
            new CustomEvent('tui-travel-information-return.setData', {
                detail: {
                    locale: searchFormData.locale,
                    country: searchFormData.countries.departures.selected[0].code
                }
            })
        );
    });

    document.querySelector('tui-travel-information-navbar')
        .addEventListener('tui-travel-information-navbar.clicked', (event) => {
            // console.log('tui-travel-information-navbar.clicked', event.detail);
            if (event.detail.id === 'return') {
                document.querySelector('tui-travel-information-destination').style.display = 'none';
                document.querySelector('tui-travel-information-return').style.display = 'block';
            } else if (event.detail.id === 'destination') {
                document.querySelector('tui-travel-information-destination').style.display = 'block';
                document.querySelector('tui-travel-information-return').style.display = 'none';
            }
        });
}

const scriptSearch = () => {};

export {
    createSearchFormTag,
    createTravelInformationTag,
    scriptTravelInformation,
    scriptSearch
}
