import config from '../profiles/config';

const toggles = {
    smartBanner: 'true',
    dataDog: 'true',
    coupons: 'true',
    exitIntentReisewunsch: 'true',
    'payments-test': 'false',
    'handle-bu-utag-view': 'true',
    'poi-hotel-map': 'true',
    'hotel-map': 'true',
    'search-result-map': 'true',
    'unsupported-browser-modal': 'true',
    'mytui-overlay': 'true'
}

const isActive = (toggleName) => {
    if (config.testRun === true) {
        return true;
    }

    return toggles[toggleName] && toggles[toggleName] == 'true';
}

export {
    isActive
}
