"use strict";
import * as service from './service';

export default function (config) {
    const plattformUrl = config.getPlattformHost();

    window.tuiCottonBall.subscribeToAllScopes('hotel-best-offer', 'instance.rendered', function (c, s, e, data) {
        service.generateCallToActionUrl(plattformUrl, data.searchParameters, data.hotelOffer, data.staticUrlParameters, data.setCallToActionUrl);
    });

    window.tuiCottonBall.subscribeToAllScopes('hotel-best-offer', 'The view settings did change.', function(component, scope, event, data){
        service.updateViewSettings(data);
    });

    return 'hotel-best-offer';
}
