import {insertAsFirstChildIfExists, getPageLanguage} from '../../elchspucke-tuicom.service';

export default class service {

    static insertTravelMap(tenant, campaignObject) {
        const getCampaignLabel = (lang, languages) => {
            return languages.filter(f => f.shortName === lang)[0].translations.label;
        };

        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (a, b, c, data) => {
            const tuiComponentTravelMap = document.querySelector('div.tui-component-travel-map');
            let label = getCampaignLabel(getPageLanguage(), campaignObject.campaignConfiguration.languages);
            const mapSectionString = this.generateMapSectionString(data, label);

            if (tuiComponentTravelMap === null) {
                const tuiResult = document.querySelector('section#tuiResult');
                const travelMapDiv = document.createElement('div');
                const lang = getPageLanguage();

                travelMapDiv.classList.add('tui-component', 'tui-component-travel-map');
                travelMapDiv.setAttribute('data-tenant', tenant);
                travelMapDiv.setAttribute('data-scope', `${campaignObject.campaignName}-${campaignObject.campaignConfiguration.campaignPeriod.startDate}`);
                travelMapDiv.setAttribute('data-mapselector', 'false');
                travelMapDiv.setAttribute('data-showpoi', 'false');
                travelMapDiv.setAttribute('data-infowindowtype', 'sidebar-right');
                travelMapDiv.setAttribute('data-lang', lang);
                travelMapDiv.setAttribute('data-currency', 'EUR');
                travelMapDiv.setAttribute('data-brand', 'tui');
                travelMapDiv.setAttribute('data-label', 'false');
                travelMapDiv.setAttribute('data-banner_headline', label);
                travelMapDiv.setAttribute('data-banner_cta_text', 'Hotels auf Karte anzeigen');
                travelMapDiv.setAttribute('data-season', 'W');
                travelMapDiv.setAttribute('data-seasonYear', '2020');
                travelMapDiv.setAttribute('data-polyline', 'false');
                travelMapDiv.setAttribute('data-morphingBanner', 'true');
                travelMapDiv.setAttribute('data-campaign', `campaign-${campaignObject.campaignName}`);
                travelMapDiv.setAttribute('data-mapSection', mapSectionString);
                travelMapDiv.setAttribute('data-searchScope', data.filters.searchScope);
                insertAsFirstChildIfExists(travelMapDiv, tuiResult);

                window.tuiCottonBall.broadcast('travel-map', 'hit me baby one more time.', {});
                window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
            }
        });

    }

    static generateMapSectionString(ibeState, label) {
        let countryId = null;
        let name = null;
        let mapSectionString = '';

        if (ibeState.hasOwnProperty('country') && ibeState.country !== null && ibeState.country.id && ibeState.country.name !== null) {
            countryId = ibeState.country.id;
            name = ibeState.country.name;
            mapSectionString = countryId + '|' + (label ? label : '') + ' ' + name;
        }

        return mapSectionString

    }

    static showMapInRegion(currentRegion, searchScope, destinations) {

        let regionId;

        if (currentRegion) {
            regionId = currentRegion.countryId;
        }

        if (typeof regionId === 'undefined') {
            return true;
        } else {
            return destinations
                .filter(destination => destination.searchScopes.indexOf(searchScope) >= 0)
                .filter(destination => destination.id === regionId)
                .length > 0;
        }
    }

    static buildCampaignData(data, campaign) {
        window.tuiCottonBall.pleaseTakeCareOf('travel-map', 'window', 'Here is your campaign data.', {data, campaign});
    }

    static isConfiguredForSearchScope(searchScope, campaignOffersSpecification) {
        if (!searchScope || typeof searchScope !== 'string' ||
            !campaignOffersSpecification || typeof campaignOffersSpecification !== 'object') {
            return false;
        }
        return (
            !campaignOffersSpecification.searchType ||
            campaignOffersSpecification.searchType === searchScope
        );
    }

}
