module.exports = {
    locale: 'de-AT',
    tenant: 'TUIAT',
    travelType: 'package',
    parameters: {
      _selectedCountriesDepartures: [],
      _suggestedCountriesDepartures: [],
      _setCountriesDataDepartures: [
        'de', 'ch', 'at'
      ],
      _selectedCountriesDestintaions: [],
      _suggestedCountriesDestintaion: [
        {code : 'ES'},{code : 'TR'},{code : 'GR'},{code : 'AT'},
        {code : 'EG'},{code : 'CV'},{code : 'TH'},{code : 'MV'},
        {code : 'US'},{code : 'AE'},{code : 'MU'},{code : 'ID'}
      ],
      _selectedCountriesNationalities: [],
      _suggestedCountriesNationalities: [
        {code : 'DE'},{code : 'AT'},{code : 'CH'}
      ],
      _selectedVaccinations: [],
      _suggestedVaccinations: [
        {key: 'vaccinated', name:'Vollständig geimpft'},
        {key: 'healed', name:'Genesen'},
        {key: 'unknown', name:'Keine Angabe'}
      ],
      _setCountriesDataVaccinations: [
        {key: 'vaccinated', name:'Vollständig geimpft'},
        {key: 'healed', name:'Genesen'},
        {key: 'unknown', name:'Keine Angabe'}
      ],
    },
    de: {
      searchButtonText: 'Suchen',
      departure: {
        heading: 'Land der Abreise',
        errorEmpty: 'Bitte gib ein Abreiseort an.',
        selected: 'Ausgewähltes Abreiseland',
        suggestions: 'Derzeit unterestützte Abreiseländer',
        placeholder: 'Von wo?',
      },
      destination: {
        heading: 'Reiseziel',
        errorEmpty: 'Bitte gib ein Reiseziel an.',
        selected: 'Ausgewähltes Reiseziel',
        suggestions: 'Beliebte Reiseziele',
        placeholder: 'Wohin geht\'s?',
      },
      nationality: {
        heading: 'Reisepass',
        errorEmpty: 'Bitte gib das Land Deines Reisepasses an.',
        selected: 'Ausgewähltes Land des Reisepasses',
        suggestions: 'Am meisten gewählt',
        placeholder: 'Land Deines Reisepasses.',
      },
      vaccination: {
        heading: 'Impf- / Genesenenstatus',
        errorEmpty: 'Sind Sie vollständig geimpft oder genesen?',
        selected: 'Dein Impf- oder Genesenenstatus',
        suggestions: 'Sind Sie vollständig geimpft oder genesen?',
        placeholder: 'Impf- / Genesenenstatus',
      }
    },
    en: {
      searchButtonText: 'Find',
      departure: {
        heading: 'Land der Abreise',
        errorEmpty: 'Bitte gib ein Abreiseort an.',
        selected: 'Ausgewähltes Abreiseland',
        suggestions: 'Derzeit unterestützte Abreiseländer',
        placeholder: 'Von wo?',
      },
      destination: {
        heading: 'Reiseziel',
        errorEmpty: 'Bitte gib ein Reiseziel an.',
        selected: 'Ausgewähltes Reiseziel',
        suggestions: 'Beliebte Reiseziele',
        placeholder: 'Wohin geht\'s?',
      },
      nationality: {
        heading: 'Reisepass',
        errorEmpty: 'Bitte gib das Land Deines Reisepasses an.',
        selected: 'Ausgewähltes Land des Reisepasses',
        suggestions: 'Am meisten gewählt',
        placeholder: 'Land Deines Reisepasses.',
      },
      vaccination: {
        heading: 'Impf- oder Genesenenstatus',
        errorEmpty: 'Sind Sie vollständig geimpft oder genesen?',
        selected: 'Dein Impf- oder Genesenenstatus',
        suggestions: 'Sind Sie vollständig geimpft oder genesen?',
        placeholder: 'Impf- oder Genesenenstatus',
      }
    },
    fr: {
      searchButtonText: 'TBD',
      departure: {
        heading: 'Land der Abreise',
        errorEmpty: 'Bitte gib ein Abreiseort an.',
        selected: 'Ausgewähltes Abreiseland',
        suggestions: 'Derzeit unterestützte Abreiseländer',
        placeholder: 'Von wo?',
      },
      destination: {
        heading: 'Reiseziel',
        errorEmpty: 'Bitte gib ein Reiseziel an.',
        selected: 'Ausgewähltes Reiseziel',
        suggestions: 'Beliebte Reiseziele',
        placeholder: 'Wohin geht\'s?',
      },
      nationality: {
        heading: 'Reisepass',
        errorEmpty: 'Bitte gib das Land Deines Reisepasses an.',
        selected: 'Ausgewähltes Land des Reisepasses',
        suggestions: 'Am meisten gewählt',
        placeholder: 'Land Deines Reisepasses.',
      },
      vaccination: {
        heading: 'Impf- oder Genesenenstatus',
        errorEmpty: 'Sind Sie vollständig geimpft oder genesen?',
        selected: 'Dein Impf- oder Genesenenstatus',
        suggestions: 'Sind Sie vollständig geimpft oder genesen?',
        placeholder: 'Impf- oder Genesenenstatus',
      }
    }
  };
  