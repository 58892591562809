import * as toggles from "../toggles";
import { browserIs } from "../util";

export default function (config) {
    if (toggles.isActive('unsupported-browser-modal') && browserIs.internetExplorer()) {
        fetch(config.getUnsupportedBrowserModalUrl())
            .then(response => response.text())
            .then(responseData => {
                const htmlContainer = document.createElement('div');
                htmlContainer.innerHTML = responseData;

                const scriptData = htmlContainer.getElementsByTagName("script")[0];
                const scriptNode = document.createElement('script');
                scriptNode.innerHTML = scriptData.innerHTML;
                htmlContainer.removeChild(scriptData);

                const bodyElement = document.querySelector('body');
                bodyElement.appendChild(htmlContainer);
                bodyElement.appendChild(scriptNode);
            });
    }

    return 'unsupported-browser-modal';
}
