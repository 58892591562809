import * as toggles from '../../toggles';
import { injectMapButton } from './service';
import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service.js';

const showAoMap = true;

export default function tuiSearchResultMap(config) {

    window.tuiCottonBall.subscribeToAllScopes('tui-search-result-map', 'IBE search result rendered.', (c, s, e, ibeData) => {

        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {

            if (!toggles.isActive('search-result-map')) return;

            if (ibeData.stepNumber !== 3 || ibeData.searchScope !== 'HOTEL') return;

            const hotelForCoordinates = (ibeData && ibeData.response && ibeData.response.hotels && ibeData.response.hotels.length) ? ibeData.response.hotels[0] : {};

            if (showAoMap) {
                injectMapButton(ibeState, hotelForCoordinates, config);
            }
        });
    });

    document.addEventListener('type', (event) => {
        const { ne, se } = event.detail;
        document.dispatchEvent(new CustomEvent('tui.ao-search-result.update-search-coordinates', { detail: { ne, se } }));
    });

    // Display markers on map when price is received
    document.addEventListener('tui.ao.search-result.price-received', (event) => {
        document.dispatchEvent(new CustomEvent('tui.search-ui.display-marker', {
            detail: event.detail
        }))
    });

    // When search is starting featching results
    document.addEventListener('tui.ao.search-result.fetching-search-result', () => {
        document.dispatchEvent(new CustomEvent('tui.ao.stop-update'))
    })

    // Done fething!?
    document.addEventListener('tui.ao.search-result.search-result-fetched', () => {
        document.dispatchEvent(new CustomEvent('tui.ao.start-update'));
    });

    // Handle the event from the map and triggers a new search in search result in order to search in the map
    document.addEventListener('tui.search-map-ui.viewport-updated', (event) => {
        document.dispatchEvent(new CustomEvent('tui.ao-search-result.update-search-coordinates', { detail: event.detail }));
    });

    // Track opening the search result map
    document.addEventListener('tui.ao.search-result-map.open-search-result-map-clicked', () => {
        elchspuckeTuicomService.trackEvent('feature', 'search-result-map', 'click', 'open');
    });

    // Track clicking on a hotel in the map
    document.addEventListener('tui.search-map-ui.tracking', (event) => {
        const { giataId = '' } = event.detail;
        elchspuckeTuicomService.trackEvent('feature', 'search-result-map', 'selectOnMap', giataId);
    });

    // Let the components know that the views settings changed
    window.tuiCottonBall.subscribeToAllScopes('tui-search-result-map', 'The view settings did change.', (_a, _b, _c, _d) => {
        document.dispatchEvent(new CustomEvent('tui.search-map.set-price-type', { detail: _d }));
        document.dispatchEvent(new CustomEvent('tui.ao.search-result.set-price-type', { detail: _d }));
    });

    // When a marker is clicked add a class to the overlay so the view map button gets margin from bottom so the card can fit below
    document.addEventListener('tui.search-map-ui.marker-clicked', () => {
        document.querySelector('.tui-search-result-map-overlay').classList.add('move-view-button');
    });

    // Removes the class so the button slides down
    document.addEventListener('tui.search-map-ui.map-clicked', () => {
        document.querySelector('.tui-search-result-map-overlay').classList.remove('move-view-button');
    });

    document.addEventListener('tui.ao.search-result.search-result-update', (event) => {
        const { result = [] } = event.detail;
        if (result.length <= 0) {
            document.dispatchEvent(new CustomEvent('tui.search-map.zero-result'));
        }
    });
    return 'tui-search-result-map';
}
