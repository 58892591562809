import * as toggles from "../toggles";
import { browserIs, readyStateComplete } from "../util";

export default function () {
    if (toggles.isActive('smartBanner') && browserIs.mobile()) {

        window.tuiCottonBall.subscribe('smart-banner', '*', 'IBE checkout confirmation page rendered.', () => {
            createMetaTags();
            readyStateComplete(appendFiles);
        });
    }

    return 'smart-banner';
}

function createMetaTags () {
    const smartBannerTitle = document.createElement('meta');
    smartBannerTitle.setAttribute('name', 'smartbanner:title');
    smartBannerTitle.setAttribute('content', 'Traumurlaub finden');

    const smartBannerAuthor = document.createElement('meta');
    smartBannerAuthor.setAttribute('name', 'smartbanner:author');
    smartBannerAuthor.setAttribute('content', 'und vieles mehr in der TUI APP');

    const smartBannerPrice = document.createElement('meta');
    smartBannerPrice.setAttribute('name', 'smartbanner:price');
    smartBannerPrice.setAttribute('content', ' ');

    const smartBannerPriceApple = document.createElement('meta');
    smartBannerPriceApple.setAttribute('name', 'smartbanner:price-suffix-apple');
    smartBannerPriceApple.setAttribute('content', ' ');

    const smartBannerPriceGoogle = document.createElement('meta');
    smartBannerPriceGoogle.setAttribute('name', 'smartbanner:price-suffix-google');
    smartBannerPriceGoogle.setAttribute('content', ' ');

    const smartBannerIconApple = document.createElement('meta');
    smartBannerIconApple.setAttribute('name', 'smartbanner:icon-apple');
    smartBannerIconApple.setAttribute('content', 'https://play-lh.googleusercontent.com/jyRCqp1gVSdlva4LaL78lkC2g_VF24IRHFTLzcNQAK_oSvEk5_i7-3Dio06KaJAuYA=s180-rw');

    const smartBannerIconGoogle = document.createElement('meta');
    smartBannerIconGoogle.setAttribute('name', 'smartbanner:icon-google');
    smartBannerIconGoogle.setAttribute('content', 'https://play-lh.googleusercontent.com/jyRCqp1gVSdlva4LaL78lkC2g_VF24IRHFTLzcNQAK_oSvEk5_i7-3Dio06KaJAuYA=s180-rw');

    const smartBannerButton = document.createElement('meta');
    smartBannerButton.setAttribute('name', 'smartbanner:button');
    smartBannerButton.setAttribute('content', 'Laden');

    const smartBannerUrlApple = document.createElement('meta');
    smartBannerUrlApple.setAttribute('name', 'smartbanner:button-url-apple');
    smartBannerUrlApple.setAttribute('content', 'https://app.adjust.com/7q3r6qm');

    const smartBannerUrlGoogle = document.createElement('meta');
    smartBannerUrlGoogle.setAttribute('name', 'smartbanner:button-url-google');
    smartBannerUrlGoogle.setAttribute('content', 'https://app.adjust.com/ht2607f');

    const smartBannerPlatforms = document.createElement('meta');
    smartBannerPlatforms.setAttribute('name', 'smartbanner:enabled-platforms');
    smartBannerPlatforms.setAttribute('content', 'android,ios');

    const smartBannerClose = document.createElement('meta');
    smartBannerClose.setAttribute('name', 'smartbanner:close-label');
    smartBannerClose.setAttribute('content', 'Schließen');

    const smartBannerTtlHide = document.createElement('meta');
    smartBannerTtlHide.setAttribute('name', 'smartbanner:hide-ttl');
    smartBannerTtlHide.setAttribute('content', '60000');

    document.head.appendChild(smartBannerTitle);
    document.head.appendChild(smartBannerAuthor);
    document.head.appendChild(smartBannerPrice);
    document.head.appendChild(smartBannerPriceApple);
    document.head.appendChild(smartBannerPriceGoogle);
    document.head.appendChild(smartBannerIconApple);
    document.head.appendChild(smartBannerIconGoogle);
    document.head.appendChild(smartBannerButton);
    document.head.appendChild(smartBannerUrlApple);
    document.head.appendChild(smartBannerUrlGoogle);
    document.head.appendChild(smartBannerPlatforms);
    document.head.appendChild(smartBannerClose);
    document.head.appendChild(smartBannerTtlHide);
}

function appendFiles() {
    const smartBannerCSS = document.createElement('link');
    smartBannerCSS.rel = 'stylesheet';
    smartBannerCSS.href = 'https://cloud.tui.com/cdn/smartbanner/current/smartbanner.min.css';

    const smartBannerJS = document.createElement('script');
    smartBannerJS.src = 'https://cloud.tui.com/cdn/smartbanner/current/smartbanner.min.js';

    document.head.appendChild(smartBannerCSS);
    document.head.appendChild(smartBannerJS);
}
