const generateRequestId = () => {
    return 'X-Tui-Mojo-' + (Math.random() + '').replace(/^0\./, '');
};

const checkStatus = (response) => {
    if (response.ok) { // res.status >= 200 && res.status < 300
        return response.json();
    } else {
        return false;
    }
};

const getDataFromURLViaPost = (url, data) => {
    if (url && data) {
        return window.fetch(url, {
            method: 'POST',
            headers: {
                'X-Tui-Requestid': generateRequestId(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(checkStatus)
            .then((response) => {
                if (response && response instanceof Array) {
                    return response;
                }
            });
    } else {
        return Promise.resolve([]);
    }
};

export {
    getDataFromURLViaPost
};
