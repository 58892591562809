export default function loadCSS(config) {
    const componentHostPath = config.getComponentsHostAndPath();
    const stage = config.getStage();

    const headNode = document.getElementsByTagName('head')[0];
    const componentNode = document.createElement('link');

    componentNode.type = 'text/css';
    componentNode.rel = 'stylesheet';
    componentNode.href = `${componentHostPath}${stage}.css`;

    headNode.appendChild(componentNode);
}
