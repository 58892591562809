import * as toggles from '../../toggles';
import { injectMapButton, injectMapComponent } from './service';
import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service.js';
import { injectMapConsent } from '../../maps-consent/1.0.0/mojo';

const showAoMap = true;

export default function tuiHotelMap(config) {

    window.tuiCottonBall.subscribeToAllScopes('tui-hotel-map', 'IBE search result rendered.', (c, s, e, ibeData) => {
        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
            if (!toggles.isActive('hotel-map')) return;
            if (ibeData.stepNumber !== 4) return;
            if (showAoMap) {
                injectMapButton(ibeState, config);
            }
        });
    });

    document.addEventListener('tui.hotel-map.open-map-clicked', () => {
        elchspuckeTuicomService.trackEvent('feature', 'map', 'click', 'open');
    });

    window.tuiCottonBall.subscribeToAllScopes('tui-hotel-map', 'Open map dialog.', () => {
        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
            injectMapConsent({
                config, onConsentApprove: () => {
                    injectMapComponent(ibeState, config);
                }, overlayId: 'tui-maps-consent'
            });
        });
    });

    document.addEventListener('tui.hotel-map.tracking', (event) => {
        const { giataId } = event.detail;
        if (giataId) {
            elchspuckeTuicomService.trackEvent('feature', 'map', 'select', giataId)
        }
    });

    return 'tui-hotel-map';
}
