import * as ElchspuckeService from '../../elchspucke-tuicom.service';
import * as LoginService from './service.js';

export default function () {
    
    /**
     * The following code is used to prepare the mojo for upcomming changes made to the template from the rTUI.
     * The template will contain skeletons of the components used in the top navigation.
     * The mojo will check for a class that is just present in the upcomming changes.
     * When this class is not present, the mojo will fallback to the previous.
     */

    const templateHasComponentSkeletons = document.querySelector('#tuiMeta .top-nav__menu.js-has-component-skeletons');
    let loginMetaNavPosition;
    
    if (templateHasComponentSkeletons) {
        let loginNavItem = document.querySelector('#tuiMeta .top-nav__menu .tui-component-login');
        if (loginNavItem) {
            loginMetaNavPosition = loginNavItem.parentNode;
        } else {
            //loginMetaNavPosition = document.querySelector('#tuiMeta .top-nav__menu li:nth-child(10)');
            loginMetaNavPosition = document.querySelector('.top-nav__component-item__favorites');
            
            const loginNode = document.createElement('li');
            loginNode.classList.add('top-nav__menu-item');

            ElchspuckeService.insertBefore(loginNode, loginMetaNavPosition);

            const separator = document.createElement('li');
            separator.className = 'top-nav__menu-separator';

            ElchspuckeService.insertAfter(separator, loginNode);

            loginMetaNavPosition = loginNode;
        }
    }

    if (loginMetaNavPosition) {
        
        loginMetaNavPosition.innerHTML =
            '<div class="top-nav__item-position / b2 u-pos-rel / tui-component tui-component-login tui-login-menu-item tui-login-fallback" data-theme="tuiLight" data-event-scope="login" data-tenant="TUIAT" data-lang="de_AT" data-visible="true" data-label="myTUI" data-authorities="MEINETUI,DUMMY" data-show-tooltip="false"></div>';
        
        LoginService.initialize(loginMetaNavPosition);

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'Login state label was clicked.', function () {
            LoginService.openDropdown();
        });

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'User logged in.', function () {
            LoginService.onLogin();
        });

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'Password forgotten.', function () {
            LoginService.onPasswordForgotten();
        });

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'Create account.', function () {
            LoginService.onGoToRegistration("register_TUI_login");
        });

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'Open login overlay.', function () {
            LoginService.showOverlay();
        });

        const registeredLoginComponents = [];
        window.tuiCottonBall.cuddleMeIf(
            'login',
            '*',
            'Register my component.',
            function (component, scope, event, data) {
                if (
                    typeof data.name === 'string' &&
                    registeredLoginComponents.indexOf(data.name) < 0 &&
                    data.name !== 'login'
                ) {
                    registeredLoginComponents.push(data.name);
                }
            }
        );

        window.tuiCottonBall.cuddleMeIf(
            'login',
            'login',
            'The current users login state.',
            function (component, scope, event, data) {
                ElchspuckeService.setCurrentUser(data);
                LoginService.addDropdownMenu(loginMetaNavPosition);
                registeredLoginComponents.forEach((componentName) => {
                    window.tuiCottonBall.broadcast(componentName, 'The current users login state.', data);
                });

                document.dispatchEvent(
                    new CustomEvent('tui-favorites.AuthenticationStateChanged', {
                        bubbles: true,
                        composed: true,
                        detail: {
                            token: data.token || null
                        }
                    })
                );
            }
        );

        window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
    }

    const searchParams = new URL(document.location).searchParams;
    if (searchParams) {
        const showLoginVerification = searchParams.get('registered');
        if (JSON.parse(showLoginVerification) === 1) {
            LoginService.showVerification();
        }
    }

    if (document.querySelector('.js-toggle-main-nav')) {
        document.querySelector('.js-toggle-main-nav').addEventListener('click', () => {
            const dropdown = document.querySelector('.tui-login-dropdown');
            const bodyItem = document.querySelector('body');
            const loginOverlay = document.querySelector('.tui-login-overlay');
            if (dropdown.style.display === 'block') {
                dropdown.style = null;
                if (bodyItem.classList.contains('dropdown-list-active')) {
                    bodyItem.classList.remove('dropdown-list-active');
                }
            } else if (loginOverlay.classList.contains('shown')) {
                loginOverlay.classList.remove('shown');
            }
        });
    }

    return 'login';
}
