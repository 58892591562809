import * as elchspuckeService from '../../elchspucke-tuicom.service';
import * as service from './service';

export default function(config) {

    const platformHost = config.getPlattformHost();
    const tenant = config.getTenant();
    const language = elchspuckeService.getPageLanguage();

    const campaignConfigurations = new Promise((resolve) => {
        window.tuiCottonBall.publish('campaign', '*','Call me back with your campaign configuration.',
            campaignConfigurations => resolve(campaignConfigurations)
        );
    });

    const generateUrl = (resetData, contentId) => {
        let targetUrl = '';
        window.tuiCottonBall.broadcast('IBE', 'generate URL', {
            filters: resetData.toBeSetFilters,
            destination: resetData.destinationObj,
            callback: function(component, scope, event, url) {
                let path = url.charAt(0) === '/' ? url.replace(/\//, '') : url;
                targetUrl = platformHost + path + '&contentid=' + contentId;
            }
        });

        return targetUrl;
    };

    window.tuiCottonBall.subscribeToAllScopes('campaign-visuals', 'IBE search result rendered.', (component, scope, event, data) => {
        campaignConfigurations.then(campaignConfigurations => {

            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
                if (data.stepNumber === 2) {
                    service.injectCampaignCardsIntoPTH2(tenant, data, ibeState, campaignConfigurations);
                }
         
                let elements = document.querySelectorAll('div[id^="tui-campaign-banner-search-result"]');

                // <div ng-switch-default="" id="tui-campaign-banner-search-result-list-bottom" class="tui-component tui-component-campaign-banner" data-lang="de_DE" data-page-name="region" data-page-number="2" data-search-scope="HOTEL" data-region-id="1006" data-region-name="Deutschland" data-campaigns="TFS" data-autostart="false"></div>

                for (let i = 0; i < elements.length; i++) {
                    const element = elements[i];

                    const regionId =  (ibeState.region && ibeState.region.id) || (ibeState.country && ibeState.country.id);
                    const regionName =  (ibeState.region && ibeState.region.name) || (ibeState.country && ibeState.country.name);
                    
                    element.setAttribute('id', element.getAttribute('id'));
                    element.setAttribute('data-page-number', JSON.stringify(data.stepNumber));
                    element.setAttribute('data-search-scope', ibeState.filters.searchScope);
                    element.setAttribute('data-region-id', regionId);
                    element.setAttribute('data-region-name', regionName);

                    if (data.stepNumber === 4 && ibeState.hotel && ibeState.hotel.giata) {
                        element.setAttribute('data-giata-code', ibeState.hotel.giata);
                    }
                    else if (ibeState.region && ibeState.region.id) {
                        element.setAttribute('data-giata-code', ibeState.region.giataId);
                    }

                    const banners = service.getPthBannerDataList(ibeState, element, campaignConfigurations, language, platformHost, generateUrl);

                    banners && banners.forEach((banner) => {
                        if (banner && banner.data && banner.position) {
                            service.removeBanner(banner.data, 'tui-campaign-banner', element);
                            service.injectBannerTag(banner.data, 'tui-campaign-banner', element);
                        }
                    });
                }

                window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
            });
        });
    });

    campaignConfigurations.then(campaignConfigurations => {
       
        const seoPageConfigurations =
            campaignConfigurations.filter((campaign) => service.detectSeoPage(window.location.pathname, campaign.campaignConfiguration.seoPages));

        if (!seoPageConfigurations || seoPageConfigurations.length === 0) {
            return;
        }

        const ibeHomesearch = document.getElementsByClassName('ibe-Homesearch');
        if (ibeHomesearch.length === 0) {
            return;
        }

        const hasClassToHideBanner = document.getElementsByClassName('no-seo');
        if (hasClassToHideBanner.length > 0) {
            return;
        }

        const container = ibeHomesearch[0].parentElement;
        const exists = container.getElementsByTagName('tui-banner');
        if (exists.length > 0) {
            return;
        }

        // If an InfoBar exists -> inject after the infoBar
        const infoBar = container.getElementsByClassName('tui-infobar--landingpage');
        const elementToInjectAfter = infoBar.length > 0 ? infoBar[0] : ibeHomesearch[0];

        const seoBannerData = service.getSeoBannerData(seoPageConfigurations, language, platformHost);
        service.injectBannerTag(seoBannerData, 'tui-campaign-banner-seo', elementToInjectAfter);

        window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
    });

    return 'campaign-visuals';
}
