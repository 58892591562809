import * as service from './service';

export default function (config) {
    const tenant = config.getTenant();
    const utagReady = new Promise((resolve) => window.addEventListener('tui-coupon.Initialized', () => resolve()));
    let showTotalPrice = false;
    let hotelBestOfferPrice = {};

    window.tuiCottonBall.subscribeToAllScopes(
        'upsell-teaser',
        'IBE search result rendered.',
        function (component, scope, event, data) {
            service.removeUpsellTeaser();
            service.removeUpsellTeaserSmall();
            service.removeElementById('tui-banner-upsell');
            service.removeElementById('tui-banner-isc');

            // 1006 - 44    Deutschland
            // 1029 - 159   Österreich
            // 1034 - 186   Schweiz
            // 1229 - 149   Niederlande
            // 1255 - 169   Polen
            // 1011 - 57    Frankreich
            // 1068 - 23    Belgien - MittelEuropa

            const ignoreCountries = [1006, 1029, 1034, 1229, 1255, 1011];
            const ignoreCountryIds = [44, 159, 186, 149, 169, 57, 23];
            const ignoreRegions = [1068];

            if (data.searchScope === 'HOTEL') {
                if (data.stepNumber === 3) {
                    window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', () => {
                        if (
                            !ignoreCountries.includes(data.data.country.countryId) &&
                            !ignoreRegions.includes(data.data.region.regionId)
                        ) {
                            service.injectUpsellBannerIntoPTH3(data);
                        }
                    });
                    utagReady.then(() => service.injectBannerIscIntoPTH3(tenant));
                }

                if (data.stepNumber === 4) {
                    window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', () => {
                        if (
                            !ignoreCountryIds.includes(data.data.country.giataCountryId) &&
                            !ignoreRegions.includes(data.data.region.regionId)
                        ) {
                            service.injectUpsellBannerIntoPTH4(data);
                        }
                    });
                }
            }
        }
    );
    const updateUpsellButton = () => {
        const price = showTotalPrice ? hotelBestOfferPrice?.total?.amount : hotelBestOfferPrice?.perPerson?.amount;

        if (price) {
            service.updateUpsellButtonPrice(price, showTotalPrice);
        }
    };
    window.tuiCottonBall.subscribeToAllScopes('hotel-best-offer', 'instance.rendered', function (c, s, e, data) {
        if (data?.hotelOffer?.price?.perPerson?.amount) {
            hotelBestOfferPrice = data.hotelOffer.price;
            updateUpsellButton();
        }
    });

    window.tuiCottonBall.subscribe(
        'IBE',
        '*',
        'The view settings did change.',
        function (component, scope, event, data) {
            showTotalPrice = data.showTotalPrice;
            updateUpsellButton();
        }
    );

    return 'upsell-teaser';
}
