const mojoService = require('./elchspucke-tuicom.service.js');
const util = require('./util');

const roomCodeMap = {
    'DR': 'ST04-DO',
    'AP': 'ST04-AP',
    'TR': 'ST04-3B',
    'ST': 'ST04-ST',
    'FR': 'ST04-FA',
    'SU': 'ST04-SU',
    'BU': 'ST04-BU',
    'SR': 'ST04-SI',
    'JS': 'ST04-JS',
    'ER': 'AT04-ECON',
    'FC': 'ST02-FI',
    'S1': 'AT04-BRO1',
    'S2': 'AT04-BRO2',
    'S3': 'AT04-BRO3',
    'SP': 'VT04-SU',
    'VF': 'ST04-HF',
    'VH': 'ST04-HO',
    'VI': 'ST04-VI',
    'WB': 'ST04-WB',
    'CA': 'GT04-RO',
    'CH': 'ST02-CH',
    'CT': 'ST02-CO',
    'HA': 'GT05-SUDI',
    'HB': 'ST04-BO',
    'MA': 'ST04-MA',
    'MH': 'ST04-CA',
    'PH': 'ST04-PE',
    'MB': 'ST04-SH',
    'XX': 'VT04-XX'
};

const boardCodeMap = {
    'AI': 'GT06-AI',
    'AO': 'GT06-AO',
    'FB': 'GT06-FB',
    'HB': 'GT06-HB',
    'XX': 'GT06-XX',
    'BB': 'GT06-BB',
    'FP': 'GT06-FBP',
    'HP': 'GT06-HBP',
    'AP': 'GT06-AIP'
};

/**
 *
 * @param {string} durationString
 * @param {string} earliestStartDate
 * @param {string} latestEndOfStay
 * @return {object} travel data containing departure date, return date and durations as an array[int]
 */
const mapIBETravelPeriod = function (durationString, startDate, endDate) {

    let startDateObject = startDate;
    let endDateObject = endDate;

    if (typeof startDate === 'string') {
        startDateObject = new Date(startDate);
    }
    if (typeof endDate === 'string') {
        endDateObject = new Date(endDate);
    }


    return {
        departureDate: util.extractDateStringWithResetTimezone(startDateObject) + 'T00:00:00+00:00',
        returnDate: util.extractDateStringWithResetTimezone(endDateObject) + 'T00:00:00+00:00',
        durations: mojoService.goAndHandlePTH4Durations(durationString, startDateObject, endDateObject)
    };
};

/**
 * @deprecated
 */
const goAndHandlePTH4Rooms = function(travellers, roomTypes, boardTypes, roomSpecificAttributes, roomTypeOpCode) {

    if (!travellers || !(travellers instanceof Array) || travellers.length < 1) {
        return false;
    }

    for (let i = 0; i < travellers.length; i++) {
        if(!travellers[i].adults || typeof travellers[i].adults !== 'number') {
            return false;
        }
    }

    let rooms = [];

    // create one room allocation for every traveller set
    for (let travellerIndex = 0; travellerIndex < travellers.length; travellerIndex++) {
        let neustaTraveller = travellers[travellerIndex];

        let room = {
            numberOfAdults: neustaTraveller.adults
        };
        if (neustaTraveller.children) {
            room.childAges = neustaTraveller.children;
        }

        // for the time beeing room codes and board codes can only be set on a per travel base by the user
        if (roomTypeOpCode) {
            // we only have a single roomTypeOpCode atm. => this may change in the future
            room.bookingCodes = [roomTypeOpCode];
        }
        if (roomTypes && roomTypes instanceof Array) {
            room.roomCodes = [];

            roomTypes.forEach((roomType) => {
                if(roomCodeMap[roomType]) {
                    room.roomCodes.push(roomCodeMap[roomType]);
                }
            });
        }
        if (boardTypes && boardTypes instanceof Array) {
            room.boardCodes = [];

            boardTypes.forEach((boardType) => {
                if(boardCodeMap[boardType]) {
                    room.boardCodes.push(boardCodeMap[boardType]);
                }
            });
        }
        if (roomSpecificAttributes && roomSpecificAttributes instanceof Array) {
            room.viewCodes = roomSpecificAttributes.indexOf('SEAVIEW') > -1 ? ['GT13-SEVI#AT13-DIRE'] : [];
        }
        rooms.push(room);
    }
    return rooms;
};

const goAndHandlePTH4RoomsWithoutMappingToGlobalTypes = function(travellers, roomTypes, boardTypes, roomSpecificAttributes, roomTypeOpCode) {

    if (!travellers || !(travellers instanceof Array) || travellers.length < 1) {
        return false;
    }

    for (let i = 0; i < travellers.length; i++) {
        if(!travellers[i].adults || typeof travellers[i].adults !== 'number') {
            return false;
        }
    }

    let rooms = [];

    // create one room allocation for every traveller set
    for (let travellerIndex = 0; travellerIndex < travellers.length; travellerIndex++) {
        let neustaTraveller = travellers[travellerIndex];

        let room = {
            numberOfAdults: neustaTraveller.adults
        };
        if (neustaTraveller.children) {
            room.childAges = neustaTraveller.children;
        }

        // for the time beeing room codes and board codes can only be set on a per travel base by the user
        if (roomTypeOpCode) {
            // we only have a single roomTypeOpCode atm. => this may change in the future
            room.bookingCodes = [roomTypeOpCode];
        }
        if (roomTypes && roomTypes instanceof Array) {
            room.roomCodes = [];

            roomTypes.forEach((roomType) => {
                if(roomCodeMap[roomType]) {
                    room.roomCodes.push(roomType);
                }
            });
        }
        if (boardTypes && boardTypes instanceof Array) {
            room.boardCodes = [];

            boardTypes.forEach((boardType) => {
                if(boardCodeMap[boardType]) {
                    room.boardCodes.push(boardType);
                }
            });
        }
        if (roomSpecificAttributes && roomSpecificAttributes instanceof Array) {
            room.viewCodes = roomSpecificAttributes.indexOf('SEAVIEW') > -1 ? ['SEAVIEW'] : [];
        }
        rooms.push(room);
    }
    return rooms;
};


/**
 * @deprecated
 */
const mapIBEFiltersToPeakwork = function(pt4FiltersObject) {

    const travelPeriod = mapIBETravelPeriod(pt4FiltersObject.duration, pt4FiltersObject.startDate, pt4FiltersObject.endDate);

    let transformedSearchParam = {
        departureDate: travelPeriod.departureDate,
        returnDate: travelPeriod.returnDate,
        durations: travelPeriod.durations,
        rooms: goAndHandlePTH4Rooms(pt4FiltersObject.travellers, pt4FiltersObject.roomTypes, pt4FiltersObject.boardTypes, pt4FiltersObject.roomSpecificAttributes, pt4FiltersObject.roomTypeOpCode),
        departureAirportCodes: pt4FiltersObject.departureAirports,
        airlineCodes: pt4FiltersObject.airlines,
        // we only have two search scopes, default to PACKAGE for eveyrthing unexpected
        searchScope: pt4FiltersObject.searchScope === 'HOTEL' ? 'HOTEL' : 'PACKAGE'
    };

    if (pt4FiltersObject.operators) {
        if(pt4FiltersObject.operators.length > 0) {
            transformedSearchParam.tourOperatorGroups = [];
            pt4FiltersObject.operators.forEach(function(element) {
                transformedSearchParam.tourOperatorGroups.push(element === 'LTUR' ? 'LMR' : element);
            });
        }
    }

    if (!isNaN(pt4FiltersObject.maxStopOver)) {
        transformedSearchParam.stopOverCount = parseInt(pt4FiltersObject.maxStopOver);
    }

    if (pt4FiltersObject.earlyBird) {
        transformedSearchParam.globalTypes = ['GT07-EB'];
    }

    if (pt4FiltersObject.nightSavings) {
        if (transformedSearchParam.globalTypes) {
            transformedSearchParam.globalTypes.push('GT07-TU');
        } else {
            transformedSearchParam.globalTypes = ['GT07-TU'];
        }
    }

    if (pt4FiltersObject.minPrice && pt4FiltersObject.minPrice > 0) {
        transformedSearchParam.minPrice = pt4FiltersObject.minPrice;
    }

    if (pt4FiltersObject.maxPrice && pt4FiltersObject.maxPrice > 0) {
        transformedSearchParam.maxPrice = pt4FiltersObject.maxPrice;
    }

    if (pt4FiltersObject.departureMaxTime && typeof pt4FiltersObject.departureMaxTime === 'string') {
        transformedSearchParam.departureMaxTime = pt4FiltersObject.departureMaxTime + 'Z';
    }

    if (pt4FiltersObject.departureMinTime && typeof pt4FiltersObject.departureMinTime === 'string') {
        transformedSearchParam.departureMinTime = pt4FiltersObject.departureMinTime + 'Z';
    }

    if (pt4FiltersObject.returnMaxTime && typeof pt4FiltersObject.returnMaxTime === 'string') {
        transformedSearchParam.returnMaxTime = pt4FiltersObject.returnMaxTime + 'Z';
    }

    if (pt4FiltersObject.returnMinTime && typeof pt4FiltersObject.returnMinTime === 'string') {
        transformedSearchParam.returnMinTime = pt4FiltersObject.returnMinTime + 'Z';
    }

    return transformedSearchParam;
};

const mapIBEFilters = function(pt4FiltersObject) {

    const travelPeriod = mapIBETravelPeriod(pt4FiltersObject.duration, pt4FiltersObject.startDate, pt4FiltersObject.endDate);

    let transformedSearchParam = {
        departureDate: travelPeriod.departureDate,
        returnDate: travelPeriod.returnDate,
        durations: travelPeriod.durations,
        rooms: goAndHandlePTH4RoomsWithoutMappingToGlobalTypes(pt4FiltersObject.travellers, pt4FiltersObject.roomTypes, pt4FiltersObject.boardTypes, pt4FiltersObject.roomSpecificAttributes, pt4FiltersObject.roomTypeOpCode),
        departureAirportCodes: pt4FiltersObject.departureAirports,
        airlineCodes: pt4FiltersObject.airlines,
        // we only have two search scopes, default to PACKAGE for eveyrthing unexpected
        searchScope: pt4FiltersObject.searchScope === 'HOTEL' ? 'HOTEL' : 'PACKAGE' //DONE
    };

    if (pt4FiltersObject.operators) { //DONE
        if(pt4FiltersObject.operators.length > 0) {
            transformedSearchParam.tourOperatorGroups = [];
            pt4FiltersObject.operators.forEach(function(element) {
                transformedSearchParam.tourOperatorGroups.push(element);
            });
        }
    }

    if (!isNaN(pt4FiltersObject.maxStopOver)) {
        transformedSearchParam.stopOverCount = parseInt(pt4FiltersObject.maxStopOver);
    }

    if (pt4FiltersObject.earlyBird) {//DONE
        transformedSearchParam.hotelAttributes = ['EARLY-BIRD'];
    }

    if (pt4FiltersObject.nightSavings) {//DONE
        if (transformedSearchParam.hotelAttributes) {
            transformedSearchParam.hotelAttributes.push('NIGHT-SAVINGS');
        } else {
            transformedSearchParam.hotelAttributes = ['NIGHT-SAVINGS'];
        }
    }

    if (pt4FiltersObject.minPrice && pt4FiltersObject.minPrice > 0) {//DONE
        transformedSearchParam.minPrice = pt4FiltersObject.minPrice;
    }

    if (pt4FiltersObject.maxPrice && pt4FiltersObject.maxPrice > 0) {//DONE
        transformedSearchParam.maxPrice = pt4FiltersObject.maxPrice;
    }

    if (pt4FiltersObject.departureMaxTime && typeof pt4FiltersObject.departureMaxTime === 'string') {
        transformedSearchParam.departureMaxTime = pt4FiltersObject.departureMaxTime + 'Z';
    }

    if (pt4FiltersObject.departureMinTime && typeof pt4FiltersObject.departureMinTime === 'string') {
        transformedSearchParam.departureMinTime = pt4FiltersObject.departureMinTime + 'Z';
    }

    if (pt4FiltersObject.returnMaxTime && typeof pt4FiltersObject.returnMaxTime === 'string') {
        transformedSearchParam.returnMaxTime = pt4FiltersObject.returnMaxTime + 'Z';
    }

    if (pt4FiltersObject.returnMinTime && typeof pt4FiltersObject.returnMinTime === 'string') {
        transformedSearchParam.returnMinTime = pt4FiltersObject.returnMinTime + 'Z';
    }

    return transformedSearchParam;
};

const mapIbeSearchParamsToSearchModel = function (ibeSearchData, initalSearchModel) {

    if (ibeSearchData.hotel && ibeSearchData.hotel.giata) {
        initalSearchModel.giataCodes = [ibeSearchData.hotel.giata];
    }
};

/**
 * @deprecated
 * @param  {object} ibeSearchData the search data retreived from the IBE (NEUSTA search model)
 * @return {object}               an object representing the common search model for all components translated from the given ibeSearchData, false if something went wrong
 */
const mapIbeSearchParamsToPeakwork = function(ibeSearchData) {
    // check IBE Searchdata
    if (!ibeSearchData || typeof ibeSearchData !== 'object' || Object.keys(ibeSearchData).length <= 0) {
        return false;
    }

    // check filters object
    if (!ibeSearchData.filters || typeof ibeSearchData.filters !== 'object' || !ibeSearchData.filters.startDate || !ibeSearchData.filters.endDate || !ibeSearchData.filters.travellers
        || !ibeSearchData.filters.travellers[0] || !ibeSearchData.filters.travellers[0]['adults'] || typeof ibeSearchData.filters.travellers[0]['adults'] !== 'number') {
        return false;
    }

    //TODO Dependency Injection einbauen
    let transformedSearchData = mapIBEFiltersToPeakwork(ibeSearchData.filters);
    mapIbeSearchParamsToSearchModel(ibeSearchData, transformedSearchData);

    return transformedSearchData;
};

const mapIbeSearchParams = function(ibeSearchData) {
    // check IBE Searchdata
    if (!ibeSearchData || typeof ibeSearchData !== 'object' || Object.keys(ibeSearchData).length <= 0) {
        return false;
    }

    // check filters object
    if (!ibeSearchData.filters || typeof ibeSearchData.filters !== 'object' || !ibeSearchData.filters.startDate || !ibeSearchData.filters.endDate || !ibeSearchData.filters.travellers
        || !ibeSearchData.filters.travellers[0] || !ibeSearchData.filters.travellers[0]['adults'] || typeof ibeSearchData.filters.travellers[0]['adults'] !== 'number') {
        return false;
    }

    let transformedSearchData = mapIBEFilters(ibeSearchData.filters);
    mapIbeSearchParamsToSearchModel(ibeSearchData, transformedSearchData);

    return transformedSearchData;

};

const ibeUrlEncode = function (toEncode) {
    const replacedSlashes = toEncode.split('/').join('|');
    const replacedWhiteSpaces = replacedSlashes.split(' ').join('-');
    return encodeURI(replacedWhiteSpaces);
};

/**
 *
 * @param duration
 * @returns {boolean} true, if given duration can be handled by the IBE as url parameter
 */
const isValidUrlDuration = function (duration) {

    let validDuration = false;

    if(duration === 'exact' || duration === 'default') {
        return true;
    }

    if (duration) {
        let parsedDuration = parseInt(duration);
        if (parsedDuration > 0 && parsedDuration < 29) {
            validDuration = true;
        }
    }

    return validDuration;
};

const mapIBETravellers = function (searchData) {
    if (!searchData || !searchData.adults) {
        return false;
    }

    let travellers = [];

    let adultsPerRoom = searchData.adults.split(',');

    for (let adults in adultsPerRoom) {
        travellers[adults] = {adults: parseInt(searchData.adults, 10)}
    }

    if (searchData.childs) {
        let childrenPerRoom = searchData.childs.split(',');

        if (adultsPerRoom.length < childrenPerRoom.length) {
            return false;
        }

        for (let room = 0; room < childrenPerRoom.length; room++) {
            if (childrenPerRoom[room].length > 0) {

                let children = childrenPerRoom[room].split(';');
                travellers[room].children = [];
                for (let child in children) {
                    travellers[room].children.push(parseInt(children[child], 10));
                }

            }
        }
    }

    return travellers;

};

export {
    mapIBETravelPeriod,
    mapIbeSearchParamsToPeakwork,
    mapIbeSearchParams,
    ibeUrlEncode,
    isValidUrlDuration,
    mapIBETravellers
};
