import { getCoupons } from './responsive';

/* Functions of preview mode */
function setPreviewModeData(data) {
    sessionStorage.setItem('tui-coupon_preview-mode_data', JSON.stringify(data));
}

function getPreviewModeData() {
    const data = sessionStorage.getItem('tui-coupon_preview-mode_data');

    return data ? JSON.parse(data) : null;
}

/* End functions of preview mode */

/**
 * Get the coupon name that last shown to the user.
 */
function getLastSeenCoupon() {
    return localStorage.getItem('tui-coupon_last-seen') || null;
}

/**
 * Set the coupon name that is currently shown to the user.
 */
function setLastSeenCoupon(couponName) {
    if (couponName) {
        localStorage.setItem('tui-coupon_last-seen', couponName);
    }
}

function clearLastSeenCoupon() {
    localStorage.removeItem('tui-coupon_last-seen');
}

function isMobileBreakpoint() {
    return !!(window.innerWidth < 768 || window.utag.data.is_mobile);
}

/**
 * This code was copied from the coupon mojo v0.5.8 and modified in some areas.
 * @param viewOptions.exitIntent Show coupon in exit intent
 * @returns
 */
function getPageData(ibeData, viewOptions = { exitIntent: false, default: true }, label = false) {
    try {
        if (typeof window.utag === 'undefined') {
            // eslint-disable-next-line no-console
            console.warn('utag not definined in coupon mojo');
            return;
        }

        /* Get airlines */
        let airlines = [];

        if (ibeData?.offer?.outboundFlight?.carrier) {
            airlines.push(ibeData.offer.outboundFlight.carrier);
        }

        if (ibeData?.offer?.returnFlight?.carrier) {
            airlines.push(ibeData.offer.returnFlight.carrier);
        }

        if (ibeData?.offer?.outboundFlight?.legs?.length) {
            airlines = airlines.concat(ibeData.offer.outboundFlight.legs.map((leg) => leg.carrier));
        }

        if (ibeData?.offer?.returnFlight?.legs?.length) {
            airlines = airlines.concat(ibeData.offer.returnFlight.legs.map((leg) => leg.carrier));
        }

        airlines = airlines.filter((airline, pos) => airlines.indexOf(airline) == pos);

        // Audience badges
        var audienceBadges = [];

        if (window.utag?.data?.badges_list) {
            audienceBadges = window.utag.data.badges_list.split(',');
        } else if (ibeData?.data?.page?.audienceBadges) {
            audienceBadges = ibeData.data.page.audienceBadges;
        } else {
            const utagBadgesKeys = Object.keys(window.utag?.data).filter((key) => key.startsWith('va.badges.'))
            utagBadgesKeys.forEach((key) => {
                if (window.utag.data[key] === true) {
                    audienceBadges.push(key.substring(10));
                }
            });
        }

        const pageData = {
            breakpoint: isMobileBreakpoint() ? 'mobile' : 'desktop',
            viewOptions: {
                exitIntent: viewOptions.exitIntent ?? false,
                default: viewOptions.default ?? true
            },
            hostname: window.location.hostname,
            href: window.location.href,
            lang: document.documentElement.getAttribute('lang') || 'de',
            cookies: document.cookie,
            pageName: window.utag.data.page_name ?? '',
            searchScope: ibeData?.searchScope || ibeData?.filters?.searchScope || 'PACKAGE', //string 'PACKAGE', 'HOTEL'
            pageType: {
                ibeBu: !!window.location.href.includes('/checkout'),
                ibeSearch: !!document.getElementById('ibeContainer'),
                editorial: !!(
                    document.querySelector('[ng-controller="HomesearchIbeController"]') ||
                    document.querySelector('[class="cs cs--ibe / row ng-scope cs--sp"]')
                )
            },
            ibeSearchStep: {
                regionList:
                    ibeData?.stepNumber === 2 ||
                    (!!document.getElementById('ibeContainer') && /_pt2/i.test(window.utag.data.page_name ?? '')),
                hotelList:
                    ibeData?.stepNumber === 3 ||
                    (!!document.getElementById('ibeContainer') && /_pt3/i.test(window.utag.data.page_name ?? '')),
                offerList:
                    ibeData?.stepNumber === 4 ||
                    (!!document.getElementById('ibeContainer') && /_pt4/i.test(window.utag.data.page_name ?? '')),
                offerListVacancy: false
            },
            regionId: undefined,
            countryId: undefined,
            countryName: undefined,
            giataId: undefined,
            searchStartDate: undefined,
            searchEndDate: undefined,
            departureAirports: undefined,
            bookingSequence: undefined,
            airlines,
            coopid: window.utag.data['qp.coopid'] ?? window.utag.data['cp.coopid_cookie'] ?? false,
            audienceBadges,
            ...(getLastSeenCoupon() ? { lastSeenCoupon: getLastSeenCoupon() } : {})
        };

        if (pageData.pageType.editorial && ibeData?.filters) {
            // This gets the landing page's pageData by the cottonBalleEvent 'Request IBE state.'
            pageData.searchScope = ibeData.filters?.searchScope || 'PACKAGE';
            pageData.searchStartDate = new Date(ibeData.filters.startDate).getTime();
            pageData.searchEndDate = new Date(ibeData.filters.endDate).getTime();
            pageData.departureAirports = ibeData.filters.departureAirports;
        } else if (pageData.pageType.ibeSearch && ibeData) {
            pageData.searchScope =
                ibeData.searchScope || ibeData.offer?.searchScope || ibeData.filters?.searchScope || 'PACKAGE';
            pageData.departureAirports =
                ibeData.departureAirports ||
                ibeData.filters?.departureAirports ||
                window.utag.data.departure_airport_searched?.split(',') ||
                [];

            /* Get search start and end date */
            if (window.utag.data.date_range_departure_searched && window.utag.data.date_range_return_searched) {
                pageData.searchStartDate = new Date(window.utag.data.date_range_departure_searched).getTime();
                pageData.searchEndDate = new Date(window.utag.data.date_range_return_searched).getTime();
            } else {
                const url = new URLSearchParams(window.location.search);
                pageData.searchStartDate = new Date(url.get('startDate')).getTime();
                pageData.searchEndDate = new Date(url.get('endDate')).getTime();
            }

            pageData.countryId = ibeData?.data?.country?.countryId || ibeData?.data?.country?.regionId || window.utag.data.ibe_countryid || ibeData?.data?.search?.countryId || ibeData?.country?.id;
            pageData.countryName = ibeData?.data?.country?.name || window.utag.data.ibe_countryname || ibeData?.data?.search?.countryName || ibeData?.country?.name;
            pageData.regionId =
                ibeData?.data?.region?.regionId || ibeData?.data?.search?.regionId || window.utag.data.ibe_regionid || ibeData?.region?.id;
            pageData.giataId =
                ibeData?.data?.search?.giataId || ibeData?.data?.hotel?.giataId || window.utag.data.hotel_giataid || ibeData?.hotel?.giata;
            pageData.bookingSequence =
                ibeData?.data?.search?.bookingSequence ||
                window.utag_data?.hotel_productcode ||
                ibeData?.cheapestOffer?.productCode ||
                ibeData?.hotel?.bookingSequence;
        }

        if (pageData.pageType.ibeBu) {
            if (typeof window.utag.data['qp.sS'] !== 'undefined') {
                pageData.searchScope = window.utag.data['qp.sS'];
            }

            pageData.giataId = Number(window.utag_data.product_giataid);
            pageData.bookingSequence = window.utag_data.hotel_productcode;
            pageData.regionId = Number(window.utag_data.ibe_regionid);
            pageData.searchStartDate = window.utag_data.date_departure_chosen_ms;
            pageData.searchEndDate = window.utag_data.date_return_chosen_ms;
            pageData.departureAirports = window.utag_data.airport_chosen?.split(',') || [];
            pageData.pricePerPerson = Array.isArray(window.utag_data.product_single_price_adults)
                ? window.utag_data.product_single_price_adults[0]
                : 0;
            pageData.pricePerBooking = parseInt(window.utag_data.product_totalprice ?? 0);
            pageData.duration = parseInt(window.utag_data.days_travel_duration_chosen ?? 0);
            pageData.operator = window.utag.data['qp.op'] || ibeData?.offer?.operator || '';
        }

        // Sets pageData for labels in offerList
        // This keeps the component compatible to the former integration.
        // This should be obsolete when the label coupons are implemented as MFEs in the future.
        // TODO: Implement label coupons as MFE and make this obsolete.
        if (label && ibeData?.offer && ibeData?.search) {
            pageData.searchScope = ibeData.offer?.searchScope;
            pageData.giataId = ibeData.search.giataId;
            pageData.bookingSequence = ibeData.search.bookingSequence;
            pageData.regionId = ibeData.search.regionId;
            pageData.searchStartDate = new Date(ibeData.offer.startDate).getTime();
            pageData.searchEndDate = new Date(ibeData.offer.endDate).getTime();
            pageData.departureAirports = ibeData?.offer?.departureAirport
                ? [ibeData.offer.departureAirport]
                : undefined;
            pageData.pricePerPerson = ibeData.offer.pricePerPerson;
            pageData.pricePerBooking = ibeData.offer.pricePerBooking;
            pageData.operator = ibeData.offer.operator;
            pageData.duration = ibeData.offer.duration;

            // Pretend beeing a BU.
            // We check a certain offer here that will be later displayed on the BU.
            // Some validators are only conducted on the BU (e.g. price).
            pageData.pageType = {
                ibeBu: true,
                ibeSearch: false,
                editorial: false,
            };
        }

        return pageData;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Coupon error in page data generation', error);
        return;
    }
}

async function trackRedeemedCoupon(appConfig) {
    // This is the code the user entered in the form, provided by neusta
    const promocode = window.utag?.data?.promocode_name;

    if (!promocode) {
        return;
    }

    // Get coupon master code hash from coupon service
    const couponServiceUrl = appConfig.getCouponServiceUrl();
    const locale = appConfig.getLanguage();
    const response = await fetch(`${couponServiceUrl}/${locale}/coupon/getMasterCodeHash/${promocode}`)

    const masterCodeHash = response.ok ? await response.text() : '';

    window.utag.link({
        link_type: 'feature',
        link_category: 'promocode_bu3',
        link_action: 'redeemed',
        link_label: masterCodeHash || promocode
    });

    setLastSeenCoupon(null);
    getCoupons().forEach(($coupon) => $coupon.remove());
}

export {
    setPreviewModeData,
    getPreviewModeData,
    getPageData,
    setLastSeenCoupon,
    clearLastSeenCoupon,
    isMobileBreakpoint,
    trackRedeemedCoupon
};
