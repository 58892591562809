export default function (config) {
    const elementsModule = document.createElement('script');
    elementsModule.type = 'module';
    elementsModule.src = `${config.getAWSUrl()}ui/ui-elements/ui-elements/ui-elements.esm.js`;
    elementsModule.async = true;

    const elementsNoModule = document.createElement('script');
    elementsNoModule.noModule = true;
    elementsNoModule.src = `${config.getAWSUrl()}ui/ui-elements/ui-elements/ui-elements.js`;
    elementsNoModule.setAttribute('data-stencil-namespace', 'ui-elements');
    elementsNoModule.async = true;

    const elementsCSS = document.createElement('link');
    elementsCSS.rel = 'stylesheet';
    elementsCSS.href = `${config.getAWSUrl()}ui/ui-elements/ui-elements/ui-elements.css`;

    document.head.appendChild(elementsModule);
    document.head.appendChild(elementsNoModule);
    document.head.appendChild(elementsCSS);
}
