import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service.js';
import { injectMapConsent } from '../../maps-consent/1.0.0/mojo.js';
import { TuiOverlay } from '../../overlay.service.js';
import { createHTMLNode } from '../../util.js';

/**
 * Creates acco only map element
 * @return {HTMLElement} acco only map element
 */
function buildAccoOnlyMapElement(giataId, cdnUrl, market, locale) {
    if (!document.getElementById('hotel-map-script')) {
        const elementsModule = Object.assign(document.createElement('script'), {
            src: `${cdnUrl}/hotel-map/current/hotel-map.esm.js`,
            id: 'hotel-map-script',
            defer: true,
            async: true,
            type: 'module'
        });
        document.head.appendChild(elementsModule);
    }

    let accoOnlyMap = createHTMLNode('tui-hotel-map', 'tui-component');
    accoOnlyMap.setAttribute('locale', locale);
    accoOnlyMap.setAttribute('hotel-id', giataId);
    accoOnlyMap.setAttribute('api-key', 'AIzaSyBwXTpsKTvJ6JFXOEJRrU9qNaup3HNAFpU'); // nosemgrep
    accoOnlyMap.setAttribute('market', market);
    accoOnlyMap.setAttribute('category-icon', 'sun');
    accoOnlyMap.setAttribute('data-theme', 'tui-light');
    accoOnlyMap.setAttribute('source-system', 'LEGACY');
    accoOnlyMap.setAttribute('mode', 'DYNAMIC');

    return accoOnlyMap;
}

/**
 * Maps travellers for acco only map travellers
 * @param {object} filters ibe state filters
 * @return {array} merged travellers array
 */
function getTravellers(filters) {
    return filters.travellers.map((room) => {
        let rooms = [];

        const adults = room && room.adults ? Array.from(Array(room.adults).keys()) : [];
        const children = room && room.children ? room.children : [];

        const travellers = [
            ...adults.map(() => {
                return 28;
            }),
            ...children.map((child) => {
                return child;
            })
        ].join();
        rooms.push(travellers);

        return rooms;
    });
}

const mapTenantToMarket = (tenant) => {
    switch (tenant) {
        case 'TUICOM':
            return 'de';
        case 'TUIAT':
            return 'at';
        case 'TUICH':
            return 'ch';
        default:
            return 'de';
    }
};

const getLocale = (config) => {
    let locale = config.getLanguage().replace('_', '-');
    const pageLanguage = elchspuckeTuicomService.getPageLanguage();
    return locale.replace(/^.{2}/g, pageLanguage);
};

let overlayElement = null;
/**
 * Injects overlay with acco only map component into body
 */
export function injectMapComponent(ibeState, config) {
    const title = ibeState.hotel.name;
    const giataId = ibeState.hotel.giata;
    const regionId = ibeState.country.giataId;
    const cdnUrl = config.getAWSCdnUrlAccoOnly();
    const market = mapTenantToMarket(config.getTenant());
    const locale = getLocale(config);

    const ibeSearchParams = elchspuckeTuicomService.mapPTH4SearchParams(ibeState.filters);

    const searchParams = {
        searchScope: ibeState.filters.searchScope,
        startDate: ibeSearchParams.startDate,
        endDate: ibeSearchParams.endDate,
        duration: ibeSearchParams.durations.join() || '7',
        ages: getTravellers(ibeState.filters).join('|'),
        airports: ibeSearchParams.departureAirportCodes.join()
    };

    let mapElement = document.querySelector('tui-hotel-map');
    if (!mapElement) {
        mapElement = buildAccoOnlyMapElement(giataId, cdnUrl, market, locale, regionId);
    }

    if (overlayElement === null) {
        const wrapper = createHTMLNode('div');
        wrapper.appendChild(mapElement);
        overlayElement = new TuiOverlay({ title, contentNode: wrapper, overlayId: 'tui-acco-only-map-overlay' });
    }

    if (mapElement.hotelId != giataId) {
        mapElement.setAttribute('hotel-id', giataId);
        overlayElement.setTitle(ibeState.hotel.name);
    }

    overlayElement.show();

    customElements.whenDefined('tui-hotel-map').then(() => {
        const hotelMap = document.querySelector('tui-hotel-map');
        hotelMap.updateSearchData(searchParams);
    });
}

/**
 * Replaces old map button (H4) with acco only map button
 */
export function injectMapButton(ibeState, config) {
    const oldMapButton = document.querySelector('#tuiTitle > div > section > div:nth-child(1) > a');
    const newMapButton = createHTMLNode('a', 'u-p-r1 u-p-l1 / btn btn-white btn-white--tdb btn-block');
    newMapButton.innerHTML = oldMapButton.innerHTML;

    newMapButton.addEventListener('click', (event) => {
        event.preventDefault();
        injectMapConsent({
            config,
            onConsentApprove: () => {
                injectMapComponent(ibeState, config);
            },
            overlayId: 'tui-maps-consent'
        });
    });

    oldMapButton.replaceWith(newMapButton);
}

export default {};
