import * as elchspuckeService from './../../elchspucke-tuicom.service';
import * as campaignService from './service';

export default function (config) {
    const campaignServicePath = config.getAWSApiUrl() + '/campaign-configuration-service/current';
    const letterCode = config.getCampaignServiceCountry();

    // fetch debug information for eventual debug mode
    const debugInfo = campaignService.extractCCSDebugInfo(elchspuckeService.getParameterByName('campaigns-forcedToBeActive'), elchspuckeService.getParameterByName('campaigns-fakedServiceTime'));

    return campaignService.fetchActiveCampaignInformation(campaignServicePath, letterCode, debugInfo)
        .then((campaigns) => {

            if (campaigns && campaigns instanceof Array && campaigns.length > 0) {

                const cleanStringFromTrailingChar = (stringToClean, lastChar) => {
                    var stringLen = stringToClean.length - 1;
                    var cleanString = stringToClean.charAt(stringLen) === lastChar ? stringToClean.substr(0, stringLen) : stringToClean;
                    return cleanString;
                };

                // clean up trailing Z from offersSpecification start and end dates to work with browser time zone
                // TODO: figure out how to work with timezones in browser together with everybody and clean this mess up
                for (let i = 0; i < campaigns.length; i++) {
                    if (campaigns[i]) {
                        if (campaigns[i].campaignConfiguration) {
                            if (campaigns[i].campaignConfiguration.offersSpecification) {
                                if (typeof campaigns[i].campaignConfiguration.offersSpecification.startDate === 'string') {
                                    campaigns[i].campaignConfiguration.offersSpecification.startDate =
                                    cleanStringFromTrailingChar(campaigns[i].campaignConfiguration.offersSpecification.startDate, 'Z');
                                }
                                if (typeof campaigns[i].campaignConfiguration.offersSpecification.endDate === 'string') {
                                    campaigns[i].campaignConfiguration.offersSpecification.endDate =
                                    cleanStringFromTrailingChar(campaigns[i].campaignConfiguration.offersSpecification.endDate, 'Z');
                                }
                            }
                        }
                    }
                }

                window.tuiCottonBall.cuddleMeIf('campaign', '*', 'Call me back with your campaign configuration.', function (component, scope, event, callback) {
                    callback(campaigns);
                });

                return 'campaign';
            }
        });
}
