/* eslint no-unused-labels: 0 */

export default {
    getComponentsHostAndPath: () => 'https://cloud.tui.com/cdn/mojo/current/tuiat/',
    getLanguage: () => 'de_AT',
    getCurrency: () => 'de',
    getTenant: () => 'TUIAT',
    getSearchDiggerTenant: () => 'tui.at',
    getVersion: () =>  'tuiat',
    getCampaignServiceCountry: () => 'AT',
    getCouponTemplatePath: () => 'fileadmin/tuiat/coupons/coupon_config_at.json',
    getCouponServiceUrl: () => 'https://api.cloud.tui.com/coupon/current/TUIAT'
};
