/* eslint no-unused-labels: 0 */

export default {
    getPlattformHost: () => {return window.location.origin + '/'},
    getAWSApiUrl: () => 'https://api.cloud.tui.com',
    getAWSUrl: () => 'https://cloud.tui.com/',
    getAWSCdnUrl: () => 'https://cloud.tui.com/cdn/',
    getAWSCdnUrlAccoOnly: () => 'https://cloud.tui.com/osp/ao/ui',
    getGebecoSrcUrl: () => 'https://api.gebeco.de/tuicom/v1/static/main.js',
    getComponentBlacklist: () => {
        return '__COMPONENTS_BLACKLIST__'
            .split(',')
            .map(value => value.trim());
    },
    getUnsupportedBrowserModalUrl: () => 'https://cloud.tui.com/cdn/modal-fragment/current/unsupported-browser/modal/index.html'
};
