class DestinationLinkHelper {
    constructor() {
        this.urlPathLocalizations = {
            "pauschalreisen": {"fr": "voyages"},
            "hotel": [],
            "hotels": [],
            "urlaub": {"fr": "vacances"},
            "suchen": {"fr": "recherche"},
            "angebote": {"fr": "offres"},
            "region": [],
            "regionen": {"fr": "regions"}
        };
        this.localizeUrlPathPartRuntimeCache = {};
    }

    generateUrl(destination, filters) {
        let url = null;
        if (destination && !destination.length) {
            if (destination.name && destination.giataId) {
                url = this.linkToHotel(destination, filters || {});
            } else if (destination.regionId && destination.regionName &&
                destination.name !== destination.regionName) {
                url = this.linkToCity(destination, filters || {});
            } else if (destination.regionId || destination.regionIds) {
                url = this.linkToRegion(destination, filters || {});
            } else if (destination.countryId) {
                url = this.linkToCountry(destination, filters || {});
            }
        } else {
            url = this.linkToCountries(filters || {});
        }
        return url;
    }

    linkToCountries(filterSettings) {
        let url = this.linkPrefix() + this.localizeUrlPathPart('regionen');
        return this.addQueryParameters(url, 'region', this.removeFilterIfNull(filterSettings));
    }

    linkToCountry(country, filterSettings) {
        if (!country) {
            return '';
        }
        if (!country.countryId) {
            return '';
        }
        if (!country.name) {
            throw 'linkToCountry needs name: ' + JSON.stringify(country);
        }
        let url = this.linkPrefix() + this.localizeUrlPathPart('regionen/') +
            this.encodeDestinationName(country.name) + '/' + country.countryId;
        return this.addQueryParameters(url, 'region', this.removeFilterIfNull(filterSettings));
    }

    linkToRegion(region, filterSettings) {
        if (!region || Object.keys(region).length === 0) {
            return '';
        }
        if (!region.name) {
            throw 'linkToRegion needs name: ' + JSON.stringify(region);
        }
        if (!region.regionId && !region.regionIds && !region.countryId) {
            throw 'linkToRegion needs regionId: ' + JSON.stringify(region);
        }
        let url = this.linkPrefix() + this.localizeUrlPathPart('hotels/') +
            this.encodeDestinationName(region.name) + '/' +
            (region.regionIds ? region.regionIds.join('|') : (region.regionId ? region.regionId : region.countryId));

        return this.addQueryParameters(url, 'hotel', this.removeFilterIfNull(filterSettings));
    }

    linkToCity(city, filterSettings) {
        if (!city || Object.keys(city).length === 0) {
            return '';
        }
        if (!city.name) {
            throw 'linkToCity needs name: ' + JSON.stringify(city);
        }
        if (!city.regionName) {
            throw 'linkToCity needs regionName: ' + JSON.stringify(city);
        }
        if (!city.regionId) {
            throw 'linkToCity needs regionId: ' + JSON.stringify(city);
        }
        let url = this.linkPrefix() + this.localizeUrlPathPart('hotels/');
        if (!filterSettings.cities || filterSettings.cities.length === 0) {
            filterSettings.cities = [city.name];
        } else if (filterSettings.cities.length) {
            filterSettings.cities.push(city.name);
        }
        url += this.encodeDestinationName(city.regionName) + '/' + city.regionId;
        return this.addQueryParameters(url, 'hotel', this.removeFilterIfNull(filterSettings));
    }

    linkToHotel(hotel, filterSettings) {
        if (!hotel || Object.keys(hotel).length === 0) {
            return '';
        }
        if (!hotel.name) {
            throw 'linkToHotel needs name: ' + JSON.stringify(hotel);
        }
        if (!hotel.giataId) {
            throw 'linkToHotel needs giataId: ' + JSON.stringify(hotel);
        }
        let url = this.linkPrefix() + this.localizeUrlPathPart('angebote/') +
            this.encodeDestinationName(hotel.name) + '/' + hotel.giataId;
        if (hotel.page) {
            url += '/' + hotel.page;
        }
        if (hotel.scrollToElement) {
            url += '/' + hotel.scrollToElement;
        }
        return this.addQueryParameters(url, 'offer', this.removeFilterIfNull(filterSettings));
    }

    removeFilterIfNull(filterSettings) {
        for (var key in filterSettings) {
            if (filterSettings[key] === null || filterSettings[key] === undefined) {
                delete filterSettings[key];
            }
        }
        return filterSettings;
    }

    pathName() {
        return window.location.pathname;
    }

    linkPrefix() {
        let path = this.pathName();
        if (path.length > 1 && path[path.length - 1] === '/') {
            path = path.substr(0, path.length - 1);
        }
        let localizedIbeUrlMarker = this.localizeUrlPathPart('/suchen/'),
            ibeMarkersToCheck = [
                {search: localizedIbeUrlMarker, replace: null},
                {search: '/suchen/', replace: localizedIbeUrlMarker},
            ];
        let linkPrefix = localizedIbeUrlMarker,
            localizedSearchRoute = this.localizeUrlPathPart('/suchen/');

        const includes = (a, b) => a.indexOf(b) !== -1;
        for (let i = 0; i < ibeMarkersToCheck.length; ++i) {
            let ibeMarker = ibeMarkersToCheck[i];
            if (includes(path, ibeMarker.search)) {
                linkPrefix = path.substring(0, path.indexOf(ibeMarker.search) + ibeMarker.search.length);
                if (ibeMarker.replace !== null) {
                    linkPrefix = linkPrefix.replace(ibeMarker.search, ibeMarker.replace);
                }
                break;
            }
        }

        let regExp;
        if (includes(linkPrefix, '/book/') ||
            includes(linkPrefix, this.localizeUrlPathPart('/book/'))) {
            regExp = new RegExp('(/book/|' + this.localizeUrlPathPart('/book/') + ').*');
            linkPrefix = linkPrefix.replace(regExp, localizedSearchRoute);
        } else if (
            includes(linkPrefix, '/urlaub/') ||
            includes(linkPrefix, '/hotels/') ||
            includes(linkPrefix, this.localizeUrlPathPart('/urlaub/')) ||
            includes(linkPrefix, this.localizeUrlPathPart('/hotels/'))
        ) {
            regExp = new RegExp('/(' +
                '(urlaub|' + this.localizeUrlPathPart('urlaub') + ')|' +
                '(hotels|' + this.localizeUrlPathPart('hotels') + ')' +
                ')/.*');
            linkPrefix = linkPrefix.replace(regExp, localizedSearchRoute);
        }

        // remove everything between "hotel|pauschalreisen" and "suchen"
        regExp = new RegExp(
            '(\\/' +
            '(' +
            'hotel|' + this.localizeUrlPathPart('hotel') +
            '|' +
            'pauschalreisen|' + this.localizeUrlPathPart('pauschalreisen') +
            ')' +
            ')(.*\\/?)' +
            '(\\/suchen|' + this.localizeUrlPathPart('\\/suchen') + ')', 'gi'
        );
        return linkPrefix
            .replace(regExp, '$1$4');
    }

    extractUsedParams(action, filterState) {
        const persistentDefaults = {
            searchScope: 'PACKAGE',
            adults: 2,
            duration: 'default',
            showTotalPrice: false,
            useExtendedFilterDefaultValues: false,
            ratings: 0,
            pageNum: 1,
            recommendationRate: 0,
            sortOffersAsc: true,
            sortHotelsAsc: false,
            sortOffersField: 'price',
            sortHotelsField: 'popularity',
            minPrice: 0,
            maxPrice: null,
            earlyBird: false,
            nightSavings: false,
            regionIds: [],
            abtestSuperLastMinute: false,
            hotelViewMode: 'list',
            departureMinTime: '00:00',
            departureMaxTime: '23:59',
            returnMinTime: '00:00',
            returnMaxTime: '23:59'
        };
        let params = {};
        if (filterState) {
            if (filterState['travellers']) {
                const travellers = filterState['travellers'];
                filterState['adults'] = travellers.map(room => room.adults)
                    .join(',');
                filterState['childs'] = travellers.map(room => room.children.join(';'))
                    .join(',');
                delete filterState['travellers'];
            }
            Object.keys(filterState)
                .forEach(key => {
                    if (filterState[key] !== persistentDefaults[key]) {
                        if (Object.prototype.toString.call(filterState[key]) === '[object Date]') {
                            params[key] = filterState[key].getFullYear() + '-' +
                                this.prefixZero(filterState[key].getMonth() + 1) + '-' +
                                this.prefixZero(filterState[key].getDate());
                        } else if (Array.isArray(filterState[key])) {
                            params[key] = filterState[key].join(';');
                        } else {
                            params[key] = filterState[key];
                        }
                    }
                });
        }
        return params;
    }

    prefixZero(n) {
        return n < 10 ? '0' + n : n;
    }

    addQueryParameters(url, stepName, filterState) {
        let params = this.extractUsedParams(stepName, filterState, null, true);
        url = url.replace('index.php', '');
        const queryString = Object.keys(params)
            .map(key => key + '=' + encodeURIComponent(params[key]))
            .join('&');
        url += queryString ? '?' + queryString : '';
        return url;
    }

    isLanguageEnvironment() {
        return window.ibe && window.ibe.brand === 'tuich';
    }

    getLanguage() {
        return document.documentElement.getAttribute('lang') || 'de';
    }

    localizeUrlPathPart(part) {
        if (!this.isLanguageEnvironment()) {
            return part;
        }

        let language = this.getLanguage(undefined),
            _cacheIdentifier = language + '::' + part;

        if (typeof this.localizeUrlPathPartRuntimeCache[_cacheIdentifier] !== 'undefined') {
            return this.localizeUrlPathPartRuntimeCache[_cacheIdentifier];
        }

        for (let localization in this.urlPathLocalizations) {
            if (!this.urlPathLocalizations.hasOwnProperty(localization)) {
                continue;
            }
            let regExp = new RegExp('(?:^|\\/)' + localization + '(?:$|\\/)'),
                partLocalization = this.urlPathLocalizations[localization];
            if (regExp.test(part) && typeof partLocalization[language] !== 'undefined') {
                part = part.replace(localization, partLocalization[language]);
            }
        }

        this.localizeUrlPathPartRuntimeCache[_cacheIdentifier] = part;
        return part;
    }

    encodeDestinationName(name) {
        name = name.replace(/\//g, '|');
        name = name.replace(/-/g, '_');
        name = name.replace(/ /g, '-');
        return name;
    }
}

const destinationLinkHelper = new DestinationLinkHelper();
export default destinationLinkHelper;
