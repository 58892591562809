const linkBuilder = require('../link-builder.service').default;

export default function() {
    window.tuiCottonBall.subscribe('IBE', '*', 'generate URL', (component, scope, event, payload) => {
        if (payload && payload.callback) {
            let url = null;
            try {
                url = linkBuilder.generateUrl(payload.destination, payload.filters || {});
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log('error building link for destination' + JSON.stringify(payload.destination) + ': ', err);
            }
            payload.callback(
                component,
                scope,
                event,
                url
            );
        }
    });

}
