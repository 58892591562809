import * as apiService from '../../api-service/1.0.1/api-service';

const fetchActiveCampaignInformation = (campaignServicePath, letterCode, debug) => {
    letterCode = letterCode.toUpperCase();
    let url = `${campaignServicePath}/countries/${letterCode}`;

    let body = {'active': true};

    if (debug) {
        body.debug = debug;
    }
    return apiService.getDataFromURLViaPost(url, body);
};

// this doesn't need excessive testing as it's only a debug method
const extractCCSDebugInfo = (campaignsString, fakedServiceTime) => {
    let debugInfo = {};

    if (campaignsString && typeof campaignsString.split === 'function') {
        debugInfo.campaignsForcedToBeActive = campaignsString.split(',');
    }

    if (fakedServiceTime && typeof fakedServiceTime === 'string') {
        debugInfo.fakedServiceTime = fakedServiceTime;
    }

    if (debugInfo.campaignsForcedToBeActive || debugInfo.fakedServiceTime) {
        return debugInfo;
    }

    return false;
};

export {
    fetchActiveCampaignInformation,
    extractCCSDebugInfo
};
