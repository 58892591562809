var nodeUrl = require('url');

var util = require('./util');
var crypto = require('./crypto');
var sha256 = require('js-sha256');

import {getConfig} from '../profiles/register-components';

var getParameterByName = function (name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

var checkParameter = function (name) {
    var value = getParameterByName(name);
    if (value) {
        return '&' + name + '=' + value;
    }
    return '';
};

var parseUrlData = function (url) {
    var parsedUrl = nodeUrl.parse(url, true);
    var pathData = [];

    if(!parsedUrl.protocol || !parsedUrl.host) {
        return false;
    }

    if(parsedUrl.pathname) {
        pathData = parsedUrl.pathname.replace(/^\//, '').split('/');
    }

    return {
        path: parsedUrl.pathname,
        pathData: pathData,
        baseUrl: parsedUrl.protocol + '//' + parsedUrl.host + '/',
        data: parsedUrl.query,
        hash: parsedUrl.hash
    };
};

// we need to keep some values for tracking, for example:
// coopid=AFF_TUI_TRIPADVISOR&utm_source=tripadvisor&utm_medium=metasearch&utm_campaign=hotelonly&utm_content=28019&utm_term=tripadvisor
var addAffiliateParameter = function () {
    var affiliateParam = '';
    affiliateParam += checkParameter('coopid');
    affiliateParam += checkParameter('utm_source');
    affiliateParam += checkParameter('utm_medium');
    affiliateParam += checkParameter('utm_campaign');
    affiliateParam += checkParameter('utm_content');
    affiliateParam += checkParameter('utm_term');
    affiliateParam += checkParameter('TAprice');
    return affiliateParam;
};

// TODO: generalize method and check for a general elchspuckeTuicomService.trackEvent("leaving"); mechanism to ensure tracking data is not corrupted (ask Björn how it works)
var trackEvent = function (link_type, link_category, action, link_label) {
    if (!window.utag || !window.utag.link || typeof window.utag.link !== 'function') {
        return false;
    } else {
        window.utag.link({ link_type: link_type, link_category: link_category, link_action: action, link_label: link_label ? link_label : 1});
    }
    return true;
};

/**
 * Tracks the given data to the given tealium key.
 * @param  {string} key                      the key used in utag_data to store the information
 * @param  {string} data                     the data to track, should not exceed 255 characters
 * @param  {boolean} triggerPageView         if true, triggers the utag.view to directly write the given data
 * @param  {boolean} triggerPageViewWithData if true, triggers the utag.view with the given data because,
 *                                           not for all cases the data of utag_data are present at this moment
 * @return {boolean}                         false if the necessary utag variables are not present, true if everything could be executed
 */
var trackData = function (key, data, triggerPageView, triggerPageViewWithData) {
    if (!window.utag_data || !window.utag) {
        return false;
    } else {
        window.utag_data[key] = data;

        if (triggerPageView && triggerPageViewWithData) {
            let viewData = {};
            viewData[key] = data;
            window.utag.view(viewData);
        } else if (triggerPageView) {
            window.utag.view();
        }

    }
    return true;
};

var getPageLanguage = function() {
    if (document.documentElement && document.documentElement.lang) {
        return document.documentElement.lang.substring(0,2);
    }
    return 'de';
};

var getLanguage = function(country) {

    var isoCode = getPageLanguage();
    var countryCode = (country && typeof country === 'string' && country.length === 2) ? country : 'DE';

    return isoCode.toLowerCase() + '_' + countryCode.toUpperCase();
};

const getPageLocale = () => {
    let locale = getConfig().getLanguage().replace('_','-');
    const pageLanguage = getPageLanguage();
    return locale.replace(/^.{2}/g, pageLanguage);
}

const getDomainLocale = () => {
    const topLevel = location.hostname.match(/(..)$/g);
    const country = topLevel && topLevel.length ? topLevel[0] : 'de';

    return getPageLanguage() + '-' + country.toUpperCase(); 
}

var insertAsFirstChildIfExists = function (newNode, referenceNode) {
    if(referenceNode && referenceNode.nodeType && newNode.nodeType) {
        referenceNode.insertBefore(newNode, referenceNode.firstChild);
    }
};

var insertBefore = function (newNode, referenceNode) {
    if(referenceNode.nodeType && newNode.nodeType) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode);
    }
};

var insertAfter = function(newNode, referenceNode) {
    if(referenceNode) {
        referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
};

var insertInPT3AtPosition = function (div, position) {
    if (!isNaN(position) && position >= 0 &&  typeof position === 'number')  {
        var elements = document.querySelectorAll('.pt__box');
        if (elements) {
            if (elements.length <= position) {
                insertAfter(div, elements[elements.length - 1]);
            } else if (position >= 0) {
                insertBefore(div, elements[position]);
            }
        }
    }
};

const insertIbeLastSeenContainer = () => {
    const ibe = document.querySelector('app-ibe')
    if(document.querySelector('#lastSeenContainer') || !ibe){
        return;
    }

    const lastSeenContainer = document.createElement('div')
    lastSeenContainer.setAttribute('id', 'lastSeenContainer')

    ibe.appendChild(lastSeenContainer)
}

const createFluidContainer = () => {
    const div = document.createElement('div');
    div.setAttribute('class', 'tui-container-fluid');
    div.setAttribute('style', 'margin-bottom:20px;');
    return div;
}

const insertFluidContainerBefore = (referenceNode) => {
    const div = createFluidContainer();
    insertBefore(div, referenceNode);
    return div;
}

const insertFluidContainerAfter = (referenceNode) => {
    const div = createFluidContainer();
    insertAfter(div, referenceNode);
    return div;
}

var removeNode = function (node) {
    node.parentNode.removeChild(node);
};

var goAndHandlePTH4Durations = function (durationString, earliestStart, latestEndOfStay) {

    //todo: check for durationString and add a test

    var travelPeriod = Math.min(util.daysBetween(earliestStart, latestEndOfStay), 28);
    var durations = [];
    if (durationString === 'exact') {
        durations.push(travelPeriod);
    } else {
        if (!durationString || durationString === 'default' || durationString === '-1') {
            if (travelPeriod <= 7) {
                durations.push(travelPeriod);
            } else {
                durations.push(7);
            }
            return durations;
        }
        var durationBoundaries = durationString.split('-');
        // we either get a normal number or something like 14- (for 2 weeks) which translates into a single number
        if (!durationBoundaries[1]) {
            durations.push(parseInt(durationBoundaries[0]));
        } else {
            // or a string like 1-4 for a range which translates into an array of durations to search for
            for (var currentDuration = parseInt(durationBoundaries[0]); currentDuration <= parseInt(durationBoundaries[1]); currentDuration++) {
                durations.push(currentDuration);
            }
        }
    }
    return durations;
};

var goAndHandlePTH4Rooms = function(travellers, roomTypes, boardTypes, roomSpecificAttributes) {

    if (!travellers || !(travellers instanceof Array) || travellers.length < 1) {
        return false;
    }

    for (var i = 0; i < travellers.length; i++) {
        if(!travellers[i].adults || typeof travellers[i].adults !== 'number') {
            return false;
        }
    }

    var rooms = [];

    // create one room allocation for every traveller set
    for (var travellerIndex = 0; travellerIndex < travellers.length; travellerIndex++) {
        var neustaTraveller = travellers[travellerIndex];

        var room = {
            numberOfAdults: neustaTraveller.adults
        };
        if (neustaTraveller.children) {
            room.childAges = neustaTraveller.children;
        }
        // for the time beeing room codes and board codes can only be set on a per travel base by the user
        if (roomTypes && roomTypes instanceof Array) {
            room.roomCodes = roomTypes;
        }
        if (boardTypes && boardTypes instanceof Array) {
            room.boardCodes = boardTypes; //todo go check this out and add in test
        }
        if (roomSpecificAttributes && roomSpecificAttributes instanceof Array) {
            room.roomSpecificAttributes = roomSpecificAttributes;
        }
        rooms.push(room);
    }
    return rooms;
};

var mapPTH4SearchParams = function(pt4FiltersObject) {

    if (!pt4FiltersObject || typeof pt4FiltersObject !== 'object' || !pt4FiltersObject.startDate || !pt4FiltersObject.endDate) {
        return false;
    }

    var startDate = pt4FiltersObject.startDate;
    var endDate = pt4FiltersObject.endDate;

    var transformedSearchParam = {
        startDate: util.extractDateStringWithResetTimezone(startDate),
        endDate: util.extractDateStringWithResetTimezone(endDate),
        durations: goAndHandlePTH4Durations(pt4FiltersObject.duration, startDate, endDate),
        rooms: goAndHandlePTH4Rooms(pt4FiltersObject.travellers, pt4FiltersObject.roomTypes, pt4FiltersObject.boardTypes, pt4FiltersObject.roomSpecificAttributes),
        departureAirportCodes: pt4FiltersObject.departureAirports,
        airlineCodes: pt4FiltersObject.airlines
    };

    if (pt4FiltersObject.operators) {
        if(pt4FiltersObject.operators.length > 0) {
            transformedSearchParam.tourOperatorGroups = [];
            pt4FiltersObject.operators.forEach(function(element) {
                transformedSearchParam.tourOperatorGroups.push(element === 'LTUR' ? 'LMR' : element);
            });
        }
    }

    if (!isNaN(pt4FiltersObject.maxStopOver)) {
        transformedSearchParam.stopOverCount = parseInt(pt4FiltersObject.maxStopOver);
    }

    return transformedSearchParam;
};

var getPageName = function() {
    var pageName = window.utag_data ? window.utag_data['page_name'] : undefined;
    if (pageName) {
        pageName = pageName.toUpperCase();
    }
    return pageName;
};

var getPageId = function() {
    var bodies = document.querySelectorAll('body');
    for (var index = 0; index < bodies.length; index++) {
        if (bodies[index].dataset && bodies[index].dataset.id) {
            return bodies[index].dataset.id;
        }
    }
    return undefined;
};

//do not use, just used temporarly for historyCards hack
var convertFiltersToSearchParams = function(filter) {
    var result = {};

    if (filter && typeof filter === 'string') {
        filter = JSON.parse(filter);
        result = mapPTH4SearchParams(filter.storage);

        if (result && result.rooms && result.rooms instanceof Array) {
            var rooms = result.rooms;

            var adultsFull = '';
            for (var r = 0; r < rooms.length; r++) {
                if (rooms[r].numberOfAdults) {
                    if (rooms.length === 1) {
                        adultsFull = rooms[0].numberOfAdults.toString();
                    } else {
                        adultsFull = adultsFull.length === 0 ? rooms[r].numberOfAdults : adultsFull + ',' + rooms[r].numberOfAdults;
                    }
                }
            }
            result.adults = adultsFull;

            var childrenFull = '';
            var allChildren = [];

            for (var room = 0; room < rooms.length; room++) {
                if (rooms[room].childAges) {

                    var currentChildren = rooms[room].childAges.join(';');
                    allChildren.push(currentChildren);
                } else {
                    allChildren.push('');
                }
            }
            childrenFull = allChildren.join();
            result.childs = childrenFull;
        }
    }

    return result;
};

var mapIbeSearchParamsToSearchModel = function (ibeSearchData, initalSearchModel) {
    if (!ibeSearchData || !initalSearchModel || typeof ibeSearchData !== 'object' || typeof initalSearchModel !== 'object' || Object.keys(ibeSearchData).length <= 0) {
        return false;
    }

    if (ibeSearchData.hotel && ibeSearchData.hotel.giata) {
        initalSearchModel.giataCodes = [ibeSearchData.hotel.giata];
    }
};

var getCookie = function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

var onlineOfflineUser = function(isOnlineCustomer) {
    return isOnlineCustomer ? "Online" : "Offline";
}

var currentUser = {};
var setCurrentUser = function(data) {
    let userId = data && data.claims && data.claims.userId;
    trackData('user_id', userId, false, false);
    
    const isOnlineCustomer = data && data.claims && data.claims.hasOwnProperty('isOnlineCustomer');
    if (isOnlineCustomer) {
        trackData('online_offline_customer', onlineOfflineUser(data.claims.isOnlineCustomer), false, false);
    }
    currentUser = data;

    const fname = data && data.claims && data.claims.first_name;
    const fnameHash = fname ? sha256(fname.toLowerCase()) : null;
    const lname = data && data.claims && data.claims.last_name;
    const lnameHash = lname ? sha256(lname.toLowerCase()) : null;
    const email = data && data.claims && data.claims.user;
    const emailHash = email ? crypto.md5(email.trim().toLowerCase() + '@gmptuide') : null;
    const emailHashSha256 = email ? sha256(email.toLowerCase()) : null;

    if (window.localStorage) {
        if (userId) {
            window.localStorage.setItem('tui-user-id', userId);
        } else {
            window.localStorage.removeItem('tui-user-id');
        }
        if (emailHash) {
            window.localStorage.setItem('tui_user_hash', emailHash);
        } else {
            window.localStorage.removeItem('tui_user_hash');
        }
        if (fnameHash && lnameHash && emailHashSha256) {
            const userData = {
                em: emailHashSha256,
                fname: fnameHash,
                lname: lnameHash
            };
            window.localStorage.setItem('tui_user_data', JSON.stringify(userData));
        } else {
            window.localStorage.removeItem('tui_user_data');
        }
    }
};

var getCurrentUser = function() {
    return currentUser;
};

var getSearchParameterFromUrl = function () {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    var searchParams = {};
    for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        searchParams[pair[0]] = pair[1];
    }
    return searchParams;
};

export {
    checkParameter,
    parseUrlData,
    getParameterByName,
    addAffiliateParameter,
    trackEvent,
    trackData,
    insertBefore,
    insertAfter,
    insertInPT3AtPosition,
    insertIbeLastSeenContainer,
    insertAsFirstChildIfExists,
    insertFluidContainerBefore,
    insertFluidContainerAfter,
    removeNode,
    mapPTH4SearchParams,
    getPageName,
    getPageId,
    convertFiltersToSearchParams,
    mapIbeSearchParamsToSearchModel,
    getCookie,
    // temporary export for ibe mapping service implementation
    goAndHandlePTH4Durations,
    goAndHandlePTH4Rooms,
    getLanguage,
    getPageLanguage,
    getPageLocale,
    getDomainLocale,
    setCurrentUser,
    getCurrentUser,
    getSearchParameterFromUrl
};
