import { removeNode } from './elchspucke-tuicom.service';
import { createHTMLNode } from './util';

const icon = '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 120 120">\n' +
  '    <path fill="#D40E14" fill-rule="evenodd" d="M38.8 48c2.273 0 3.987.715 4.662 3.923.746 3.57.436 6.06-3.598 6.345l-7.275.516c5.354 33.678 36.657 44.65 53.704 8.38 2.817-6.001 3.743-7.096 6.831-6.33 4.19 1.034 4.765 3.179 2.768 9.228-14.738 44.718-64.266 49.2-73.983-10.513l-5.911.423C11.192 60.317 11 56.28 11 54.032 11 49.59 12.657 48 15.632 48zm61.7-22a8.5 8.5 0 1 1 0 17 8.5 8.5 0 0 1 0-17z"/>\n' +
  '</svg>';

export class TuiOverlay {

  shownClass = 'shown';
  modalId = 'tui-overlay-modal';
  overlayIconId = `tui-overlay-icon`;
  overlayTitleId = `tui-overlay-title`;
  modalCloseId = `tui-overlay-modal-close`;
  cssClasses = '';
  overlayElement;
  htmlElement = document.getElementsByTagName('html')[0]

  constructor(options) {
    const { overlayId = 'tui-overlay', title = '', contentNode = '' } = options;
    this.overlayId = overlayId
    this.title = title;
    this.contentNode = contentNode;
    document.addEventListener(`tui-overlay.hide.${this.overlayId}`, () => {
      this.hide();
    })
    this.buildOverlayElement();
  }

  buildOverlayElement() {
    this.overlayElement = createHTMLNode('div', `tui-overlay ${this.overlayId} `, this.overlayId);

    const overlayIcon = createHTMLNode('div', this.overlayIconId, this.overlayIconId);
    overlayIcon.innerHTML = icon;

    const overlayTitleText = createHTMLNode('span', `${this.overlayTitleId}-text`);
    overlayTitleText.innerHTML = this.title;
    const closeButton = createHTMLNode('i', `tui-icon--close ${this.modalCloseId}`, this.modalCloseId);
    const overlayTitle = createHTMLNode('div', this.overlayTitleId, this.overlayTitleId);
    overlayTitle.appendChild(overlayIcon);
    overlayTitle.appendChild(overlayTitleText);
    overlayTitle.appendChild(closeButton);
    this.overlayElement.appendChild(overlayTitle);

    let modal = createHTMLNode('div', this.modalId, this.modalId);
    modal.appendChild(this.contentNode);

    const wrapper = createHTMLNode('div', `tui-overlay-wrapper`);
    wrapper.appendChild(modal);
    this.overlayElement.appendChild(wrapper);

    this.overlayElement.addEventListener('click', (event) => {
      if (
        event.target.closest(`#${this.overlayId}`)
        && event.target.closest(`#${this.overlayId}`).classList.contains(this.shownClass)
        && !wrapper.contains(event.target)) {
        this.hide();
      }
    }, false);

    closeButton.addEventListener('click', () => {
      this.hide();
    });

    document.body.append(this.overlayElement);
  }

  setContentNode(contentNode) {
    this.contentNode = contentNode;
  }

  setTitle(title){
    const titleElement = document.querySelector(`#${this.overlayId} .${this.overlayTitleId}-text`)
    if(titleElement){
        titleElement.innerHTML = title
    }
  }

  hide() {
    this.htmlElement.style.overflow = '';
    this.overlayElement.classList.remove(this.shownClass);
  }

  show() {
    this.htmlElement.style.overflow = 'hidden';
    this.overlayElement.classList.add(this.shownClass);
  }

  destroy() {
    removeNode(this.overlayElement);
  }
}
